import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from "../../../components/FormElements";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import { getBase64, getReturnUrl } from "../../../utils";

const OperatorsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [services, setServices] = useState([]);
  const [servicesId, setServicesId] = useState("");
  const [operatorName, setOperatorName] = useState("");
  const [operatorLogo, setOperatorLogo] = useState([]);
  const [operatorLogoBase64, setOperatorLogoBase64] = useState("");
  const [planApiCode, setPlanApiCode] = useState("");
  const [backupPlanApiCode, setBackupPlanApiCode] = useState("");
  const [isSTV, setIsSTV] = useState("No");
  const [isActiveForApiUser, setIsActiveForApiUser] = useState("Active");
  const [isActiveForRetailer, setIsActiveForRetailer] = useState("Active");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [minAmount, setMinAmount] = useState("1");
  const [maxAmount, setMaxAmount] = useState("10000");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setServicesId("");
    setOperatorName("");
    setOperatorLogo([]);
    setOperatorLogoBase64("");
    setPlanApiCode("");
    setBackupPlanApiCode("");
    setIsSTV("No");
    setIsActiveForApiUser("Active");
    setIsActiveForRetailer("Active");
    setField1("");
    setField2("");
    setField3("");
    setField4("");
    setMinAmount("");
    setMaxAmount("");
  };

  const readServices = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/services")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setServices(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      servicesId: servicesId,
      operatorName: operatorName,
      operatorLogo: operatorLogoBase64,
      planApiCode: planApiCode,
      backupPlanApiCode: backupPlanApiCode,
      isSTV: isSTV,
      isActiveForApiUser: isActiveForApiUser,
      isActiveForRetailer: isActiveForRetailer,
      field1: field1,
      field2: field2,
      field3: field3,
      field4: field4,
      minAmount: minAmount,
      maxAmount: maxAmount,
    };
    API.post("/recharge-configurations/operators", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (operatorLogo?.length > 0) {
      getBase64(operatorLogo[0], setOperatorLogoBase64);
    }
  }, [operatorLogo]);

  useEffect(() => {
    readServices();
  }, [readServices]);

  const servicesOptions = [{ title: "-- SELECT SERVICE --", value: "" }];
  services.forEach(({ id, serviceName }) =>
    servicesOptions.push({
      title: serviceName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              "calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Service</Label>
                <Dropdown
                  placeholder="Please select service"
                  value={servicesId}
                  onChange={setServicesId}
                  options={servicesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Is STV</Label>
                <Dropdown
                  placeholder="Please select if operator is stv"
                  value={isSTV}
                  onChange={setIsSTV}
                  options={[
                    {
                      title: "Yes",
                      value: "Yes",
                    },
                    {
                      title: "No",
                      value: "No",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Is Active For Api User ?</Label>
                <Dropdown
                  placeholder="Please select if operator is active for api user"
                  value={isActiveForApiUser}
                  onChange={setIsActiveForApiUser}
                  options={[
                    {
                      title: "Active",
                      value: "Active",
                    },
                    {
                      title: "Inactive",
                      value: "Inactive",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Is Active For Other Users ?</Label>
                <Dropdown
                  placeholder="Please select if operator is active for other users"
                  value={isActiveForRetailer}
                  onChange={setIsActiveForRetailer}
                  options={[
                    {
                      title: "Active",
                      value: "Active",
                    },
                    {
                      title: "Inactive",
                      value: "Inactive",
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Operator Name</Label>
                <TextInput
                  value={operatorName}
                  onChange={setOperatorName}
                  placeholder="Please enter operator name"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Plan API Operator Code</Label>
                <TextInput
                  value={planApiCode}
                  onChange={setPlanApiCode}
                  placeholder="Please enter plan api operator code"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Backup Plan API Operator Code</Label>
                <TextInput
                  value={backupPlanApiCode}
                  onChange={setBackupPlanApiCode}
                  placeholder="Please enter backup plan api operator code"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Operator Logo</Label>
            <FileInput
              files={operatorLogo}
              onChange={setOperatorLogo}
              accept="image/*"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label required>Min Amount</Label>
                <NumberInput
                  value={minAmount}
                  onChange={setMinAmount}
                  placeholder="Please enter min amount"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Max Amount</Label>
                <NumberInput
                  value={maxAmount}
                  onChange={setMaxAmount}
                  placeholder="Please enter max amount"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Field 1</Label>
                <TextInput
                  value={field1}
                  onChange={setField1}
                  placeholder="Please enter field 1"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Field 2</Label>
                <TextInput
                  value={field2}
                  onChange={setField2}
                  placeholder="Please enter field 2"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
            <GridItem>
              <Fieldset>
                <Label>Field 3</Label>
                <TextInput
                  value={field3}
                  onChange={setField3}
                  placeholder="Please enter field 3"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Field 4</Label>
                <TextInput
                  value={field4}
                  onChange={setField4}
                  placeholder="Please enter field 4"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type="submit">
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(OperatorsCreate);
