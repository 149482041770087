import React, { useState, useEffect, useCallback } from "react";
import {
  faCircleCheck,
  faCircleXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import exportFromJSON from "export-from-json";
import JSONPretty from "react-json-pretty";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";
import {
  MessageBox,
  CustomButton,
  Fieldset,
  TextInput,
  Button,
  Form,
  ResetButton,
  Dropdown,
  CustomConfirmButton,
  Label,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Modal from "../../../components/Modal";
import {
  StyledFiltersContainer,
  StyledApiCallLogsContainer,
  StyledBodyContainer,
  StyledHeading,
  StyledContent,
  StyledModalContainer,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from "../../../constants";
import {
  getSearchParams,
  authData,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import Accordination from "../../../components/Accordination/Accordination";
import { Grid, GridItem } from "../../../components/Grid";

const DisputedTransactionsTable = (props) => {
  const { accountType } = authData.get();
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 100,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [operators, setOperators] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [number, setNumber] = useState("");
  const [operatorsId, setOperatorsId] = useState("");
  const [transactionsId, setTransactionsId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [apiProviders, setApiProviders] = useState([]);
  const [apiProvidersId, setApiProvidersId] = useState("");
  const [rolesId, setRolesId] = useState("");
  const [accountsId, setAccountsId] = useState("");
  const [resolutionStatus, setResolutionStatus] = useState("Pending");
  const [accounts, setAccounts] = useState([]);

  const [logs, setLogs] = useState([]);

  const readApiLogs = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/transactions/logs/${id}`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setLogs(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const toggleApiLogs = (id) => {
    setShowModal(true);
    readApiLogs(id);
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setRolesId("");
    setAccountsId("");
    setNumber("");
    setTransactionsId("");
    setApiProvidersId("");
    setOperatorsId("");
    setStartDate("");
    setEndDate("");
    setResolutionStatus("Pending");
    readData(
      number,
      operatorsId,
      transactionsId,
      startDate,
      endDate,
      apiProvidersId,
      accountsId,
      "Pending"
    );
  };

  const readAccounts = () => {
    API.get("/accounts-management/accounts").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setAccounts(data);
      }
    });
  };

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/recharge-configurations/operators`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readApiProviders = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/recharge-configurations/api-providers`)
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setApiProviders(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readData = useCallback(
    (
      number = "",
      operatorsId = "",
      transactionsId = "",
      startDate = "",
      endDate = "",
      apiProvidersId = "",
      accountsId = "",
      resolutionStatus = "Pending"
    ) => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/transactions/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=&disputeStatus=Disputed&resolutionStatus=${resolutionStatus}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setTransactions(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const updateDisputedTransaction = (id, processStatus) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(
      `/transactions/update-dispute/${id}?processStatus=${processStatus}&pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=&disputeStatus=Disputed`
    )
      .then((response) => {
        const { status, message, data, pageInfo } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setTransactions(data);
            setTotalRecords(pageInfo.totalRecords);
          }
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(
      number,
      operatorsId,
      transactionsId,
      startDate,
      endDate,
      apiProvidersId,
      accountsId,
      resolutionStatus
    );
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const onClickExport = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      `/transactions/export/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&faccountsId=${accountsId}&number=${number}&apiProvidersId=${apiProvidersId}&operatorsId=${operatorsId}&transactionsId=${transactionsId}&startDate=${startDate}&endDate=${endDate}&transactionsStatus=&disputeStatus=Disputed`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          exportFromJSON({
            data,
            fileName: "transactions",
            exportType: exportFromJSON.types.csv,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    readOperators();
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readApiProviders();
    }
  }, [readOperators, readApiProviders, accountType]);

  useEffect(() => {
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
        ACCOUNT_TYPES.API_USERS,
      ].includes(accountType)
    ) {
      readAccounts();
    }
  }, [accountType]);

  useEffect(() => {
    if (!isFiltering) {
      readData(
        number,
        operatorsId,
        transactionsId,
        startDate,
        endDate,
        apiProvidersId,
        accountsId,
        resolutionStatus
      );
    }
  }, [paginationData, readData, accountType]);

  const { accountsPermissions } = props;
  const canUpdateDispute =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE_DISPUTE) || false;
  const canExport =
    accountsPermissions.includes(PERMISSION_TYPES.EXPORT) || false;
  const canTrack =
    accountsPermissions.includes(PERMISSION_TYPES.TRACK) || false;

  const rolesOptions = [{ title: "-- SELECT ROLE --", value: "" }];
  if (accountType === ACCOUNT_TYPES.MASTER_DISTRIBUTORS) {
    [
      {
        id: 3,
        roleName: "Distributors",
      },
      {
        id: 4,
        roleName: "Retailers",
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else if (accountType === ACCOUNT_TYPES.DISTRIBUTORS) {
    [
      {
        id: 4,
        roleName: "Retailers",
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  } else {
    [
      {
        id: 2,
        roleName: "Master Distributors",
      },
      {
        id: 3,
        roleName: "Distributors",
      },
      {
        id: 4,
        roleName: "Retailers",
      },
      {
        id: 5,
        roleName: "Api Users",
      },
    ].forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );
  }

  const accountsOptions = [{ title: "-- SELECT ACCOUNT --", value: "" }];
  accounts
    .filter((account) => account?.isDeleted === "No")
    .filter(({ accountsRolesId }) =>
      rolesId !== ""
        ? accountsRolesId === rolesId
        : [2, 3, 4, 5].includes(accountsRolesId)
    )
    .forEach(({ id, firstName, lastName }) =>
      accountsOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
      })
    );

  const operatorsOptions = [{ title: "-- SELECT OPERATOR --", value: "" }];
  operators.forEach(({ id, operatorName }) =>
    operatorsOptions.push({
      title: operatorName,
      value: id,
    })
  );

  const apiProvidersOptions = [
    { title: "-- SELECT API PROVIDER --", value: "" },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        reloadData={reloadData}
        showExport={canExport}
        onClickExport={onClickExport}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <>
              <Fieldset>
                <Label>Role</Label>
                <Dropdown
                  style={{ border: "1px solid #cacaca" }}
                  placeholder="Role"
                  value={rolesId}
                  onChange={(value) => {
                    setAccountsId("");
                    setRolesId(value);
                  }}
                  options={rolesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: "1px solid #cacaca" }}
                  placeholder="Account"
                  value={accountsId}
                  onChange={setAccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </>
          )}
          <Fieldset>
            <Label>Mobile/Account Number</Label>
            <TextInput
              value={number}
              onChange={setNumber}
              placeholder="Mobile/Account Number"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Transaction ID</Label>
            <TextInput
              value={transactionsId}
              onChange={setTransactionsId}
              placeholder="Transactions ID"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          {![
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) && (
            <Fieldset>
              <Label>API Provider</Label>
              <Dropdown
                style={{ border: "1px solid #cacaca" }}
                placeholder="API Provider"
                value={apiProvidersId}
                onChange={setApiProvidersId}
                options={apiProvidersOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label>Operator</Label>
            <Dropdown
              placeholder="Operator"
              value={operatorsId}
              onChange={setOperatorsId}
              options={operatorsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={startDate}
              onChange={setStartDate}
              placeholder="Start Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={endDate}
              onChange={setEndDate}
              placeholder="End Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Resolution Status</Label>
            <Dropdown
              style={{ border: "1px solid #cacaca" }}
              placeholder="Resolution Status"
              value={resolutionStatus}
              onChange={setResolutionStatus}
              options={[
                {
                  title: "-- SELECT STATUS --",
                  value: "",
                },
                {
                  title: "All",
                  value: "All",
                },
                {
                  title: "Pending",
                  value: "Pending",
                },
                {
                  title: "Resolved",
                  value: "Resolved",
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && transactions?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          transactions?.length === 0
        }
        message={`No transactions found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {transactions?.length > 0 && (
        <>
          {[
            ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
            ACCOUNT_TYPES.DISTRIBUTORS,
            ACCOUNT_TYPES.RETAILERS,
            ACCOUNT_TYPES.API_USERS,
          ].includes(accountType) ? (
            <TableBuilder
              isLoading={transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: "",
                  dataSelector: "id",
                  dataType: "string",
                  CellRenderer: (value) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: "orange" }}
                        />
                      )}
                    </>
                  ),
                },
                {
                  title: "Mobile/Account Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                  CellRenderer: (value) => value,
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  align: "center",
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Transaction Status",
                  dataSelector: "processedStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Success"
                            ? "green"
                            : value === "Failure"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "Dispute Status",
                  dataSelector: "processedDisputeStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Accepted"
                            ? "green"
                            : value === "Failure" || value === "Rejected"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "API Operator ID",
                  dataSelector: "apiOperatorsId",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "API Users Transaction ID",
                  dataSelector: "apiUsersTransactionsId",
                  dataType: "string",
                  align: "center",
                  requiredRoles: [ACCOUNT_TYPES.API_USERS],
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Disputed Date",
                  dataSelector: "disputeRaisedTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Resolution Date",
                  dataSelector: "disputeResolutionTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Transaction Date",
                  dataSelector: "createdOn",
                  dataType: "date",
                  align: "center",
                },
                {
                  title: "Recharge Circle",
                  dataSelector: "rechargeCircle",
                  dataType: "string",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
              ]}
              tableData={transactions}
            />
          ) : [ACCOUNT_TYPES.STAFF].includes(accountType) ? (
            <TableBuilder
              isLoading={transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: "",
                  dataSelector: "id",
                  sticky: true,
                  canSort: false,
                  width: "100px",
                  CellRenderer: (value, item) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: "orange" }}
                        />
                      )}
                      {canUpdateDispute &&
                        (item.processedDisputeStatus === "Pending" ||
                          item.processedDisputeStatus === "Reopened") && (
                          <>
                            <CustomConfirmButton
                              icon={faCircleCheck}
                              title="Approve Dispute"
                              message="Are you sure, you want to approve this dispute ?"
                              proceedButtonText="Approve"
                              cancelButtonText="Cancel"
                              onClick={() =>
                                updateDisputedTransaction(value, "Approved")
                              }
                              color="forestgreen"
                              hoverColor="#006200"
                            />
                            <CustomConfirmButton
                              icon={faCircleXmark}
                              title="Reject Dispute"
                              message="Are you sure, you want to reject this dispute ?"
                              proceedButtonText="Reject"
                              cancelButtonText="Cancel"
                              onClick={() =>
                                updateDisputedTransaction(value, "Rejected")
                              }
                            />
                          </>
                        )}
                    </>
                  ),
                },
                {
                  title: "Transactions ID",
                  dataSelector: "id",
                  dataType: "number",
                },
                {
                  title: "Transaction Date",
                  dataSelector: "createdOn",
                  dataType: "date",
                  align: "center",
                },
                {
                  title: "Disputed Date",
                  dataSelector: "disputeRaisedTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Accounts Name",
                  dataSelector: "accountsName",
                  dataType: "string",
                },
                {
                  title: "Mobile/Account Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                  CellRenderer: (value) => value,
                },
                {
                  title: "Transaction Status",
                  dataSelector: "processedStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Success"
                            ? "green"
                            : value === "Failure"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "Dispute Status",
                  dataSelector: "processedDisputeStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Accepted"
                            ? "green"
                            : value === "Failure" || value === "Rejected"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "API Operator ID",
                  dataSelector: "apiOperatorsId",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  align: "center",
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: "Resolution Date",
                  dataSelector: "disputeResolutionTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Recharge Circle",
                  dataSelector: "rechargeCircle",
                  dataType: "string",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
              ]}
              tableData={transactions}
            />
          ) : (
            <TableBuilder
              isLoading={transactions?.length !== 0 && isLoading}
              tableHeadings={[
                {
                  title: "",
                  dataSelector: "id",
                  sticky: true,
                  canSort: false,
                  width: "100px",
                  CellRenderer: (value, item) => (
                    <>
                      {canTrack && (
                        <CustomButton
                          icon={faEye}
                          onClick={() => toggleApiLogs(value)}
                          style={{ color: "orange" }}
                        />
                      )}
                      {canUpdateDispute &&
                        (item.processedDisputeStatus === "Pending" ||
                          item.processedDisputeStatus === "Reopened") && (
                          <>
                            <CustomConfirmButton
                              icon={faCircleCheck}
                              title="Approve Dispute"
                              message="Are you sure, you want to approve this dispute ?"
                              proceedButtonText="Approve"
                              cancelButtonText="Cancel"
                              onClick={() =>
                                updateDisputedTransaction(value, "Approved")
                              }
                              color="forestgreen"
                              hoverColor="#006200"
                            />
                            <CustomConfirmButton
                              icon={faCircleXmark}
                              title="Reject Dispute"
                              message="Are you sure, you want to reject this dispute ?"
                              proceedButtonText="Reject"
                              cancelButtonText="Cancel"
                              onClick={() =>
                                updateDisputedTransaction(value, "Rejected")
                              }
                            />
                          </>
                        )}
                    </>
                  ),
                },
                {
                  title: "Transactions ID",
                  dataSelector: "id",
                  dataType: "number",
                },
                {
                  title: "Transaction Date",
                  dataSelector: "createdOn",
                  dataType: "date",
                  align: "center",
                },
                {
                  title: "Disputed Date",
                  dataSelector: "disputeRaisedTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Accounts Name",
                  dataSelector: "accountsName",
                  dataType: "string",
                },
                {
                  title: "Mobile/Account Number",
                  dataSelector: "rechargeNumber",
                  dataType: "string",
                },
                {
                  title: "Operator",
                  dataSelector: "operatorName",
                  dataType: "string",
                  CellRenderer: (value) => value,
                },
                {
                  title: "Transaction Status",
                  dataSelector: "processedStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Success"
                            ? "green"
                            : value === "Failure"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "Dispute Status",
                  dataSelector: "processedDisputeStatus",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) =>
                    value && (
                      <StatusHighliter
                        className={
                          value === "Accepted"
                            ? "green"
                            : value === "Failure" || value === "Rejected"
                            ? "red"
                            : value === "Pending"
                            ? "yellow"
                            : "blue"
                        }
                      >
                        {value}
                      </StatusHighliter>
                    ),
                },
                {
                  title: "API Provider",
                  dataSelector: "apiProvidersName",
                  dataType: "string",
                },
                {
                  title: "API Operator ID",
                  dataSelector: "apiOperatorsId",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Amount",
                  dataSelector: "amount",
                  dataType: "number",
                  align: "center",
                  CellRenderer: (value) => formatCurrency(value),
                },
                {
                  title: "API Transaction ID",
                  dataSelector: "apiTransactionsId",
                  dataType: "string",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Resolution Date",
                  dataSelector: "disputeResolutionTimestamp",
                  dataType: "date",
                  align: "center",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
                {
                  title: "Recharge Circle",
                  dataSelector: "rechargeCircle",
                  dataType: "string",
                  CellRenderer: (value) => (value ? value : "N/A"),
                },
              ]}
              tableData={transactions}
            />
          )}
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setLogs([]);
            }}
            hideCloseButton
          >
            <StyledModalContainer width={`1200px`}>
              <h3>API Call Logs</h3>
              <StyledApiCallLogsContainer>
                <LoadingSpinner
                  isLoading={
                    responseStatus === "" && logs?.length === 0 && isLoading
                  }
                />
                <NoData
                  status={
                    responseStatus !== API_RESPONSE_TYPES.FAILURE &&
                    !isLoading &&
                    logs?.length === 0
                  }
                  message={`No api logs found`}
                />
                <MessageBox status={responseStatus} message={responseMessage} />
                {logs?.length > 0 &&
                  logs.map(
                    (
                      {
                        createdOn,
                        processType,
                        request,
                        requestBody,
                        response,
                        responseType,
                        responseTime,
                      },
                      index
                    ) => (
                      <Accordination
                        key={index}
                        header={`${processType}`}
                        autoExpanded={true}
                      >
                        <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
                          <GridItem>
                            <StyledBodyContainer>
                              <StyledHeading>
                                API Request {createdOn && `( ${createdOn} )`}
                              </StyledHeading>
                              <StyledContent>{request}</StyledContent>
                            </StyledBodyContainer>
                            {requestBody && (
                              <StyledBodyContainer>
                                <StyledHeading>API Request Body</StyledHeading>
                                <StyledContent>{requestBody}</StyledContent>
                              </StyledBodyContainer>
                            )}
                          </GridItem>
                          <GridItem>
                            <StyledBodyContainer>
                              <StyledHeading>
                                API Response{" "}
                                {responseTime && `( ${responseTime} )`}
                              </StyledHeading>
                              <StyledContent>
                                {responseType === "XML" ? (
                                  <code>{response}</code>
                                ) : (
                                  <JSONPretty
                                    id="json-pretty"
                                    data={response}
                                  ></JSONPretty>
                                )}
                              </StyledContent>
                            </StyledBodyContainer>
                          </GridItem>
                        </Grid>
                      </Accordination>
                    )
                  )}
              </StyledApiCallLogsContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(DisputedTransactionsTable);
