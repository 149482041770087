import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  FileInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const ServicesUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [showImage, setShowImage] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [serviceIcon, setServiceIcon] = useState([]);
  const [serviceIconBase64, setServiceIconBase64] = useState('');
  const [serviceAppIcon, setServiceAppIcon] = useState([]);
  const [serviceAppIconBase64, setServiceAppIconBase64] = useState('');
  const [surcharge, setSurcharge] = useState('');
  const [defaultMasterDistributorsShare, setDefaultMasterDistributorsShare] =
    useState('');
  const [defaultDistributorsShare, setDefaultDistributorsShare] = useState('');
  const [defaultRetailersShare, setDefaultRetailersShare] = useState('');
  const [defaultApiUsersShare, setDefaultApiUsersShare] = useState('');
  const [serviceStatus, setServiceStatus] = useState('');
  const [enablePlanApi, setEnablePlanApi] = useState('');
  const [enableBillFetchApi, setEnableBillFetchApi] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      serviceName,
      serviceIcon,
      serviceAppIcon,
      surcharge,
      defaultMasterDistributorsShare,
      defaultDistributorsShare,
      defaultRetailersShare,
      defaultApiUsersShare,
      serviceStatus,
      enablePlanApi,
      enableBillFetchApi,
    } = originalFormData;
    setServiceName(serviceName);
    setServiceIcon([]);
    setServiceIconBase64(serviceIcon);
    setServiceAppIcon([]);
    setServiceAppIconBase64(serviceAppIcon);
    setSurcharge(surcharge);
    setDefaultMasterDistributorsShare(defaultMasterDistributorsShare);
    setDefaultDistributorsShare(defaultDistributorsShare);
    setDefaultRetailersShare(defaultRetailersShare);
    setDefaultApiUsersShare(defaultApiUsersShare);
    setServiceStatus(serviceStatus);
    setEnablePlanApi(enablePlanApi);
    setEnableBillFetchApi(enableBillFetchApi);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/recharge-configurations/services/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            serviceName,
            serviceIcon,
            serviceAppIcon,
            surcharge,
            defaultMasterDistributorsShare,
            defaultDistributorsShare,
            defaultRetailersShare,
            defaultApiUsersShare,
            serviceStatus,
            enablePlanApi,
            enableBillFetchApi,
          } = data[0];
          setServiceName(serviceName);
          setServiceIconBase64(serviceIcon);
          setServiceAppIconBase64(
            serviceAppIcon && serviceAppIcon !== 'null' ? serviceAppIcon : ''
          );
          setSurcharge(surcharge);
          setDefaultMasterDistributorsShare(defaultMasterDistributorsShare);
          setDefaultDistributorsShare(defaultDistributorsShare);
          setDefaultRetailersShare(defaultRetailersShare);
          setDefaultApiUsersShare(defaultApiUsersShare);
          setServiceStatus(serviceStatus);
          setEnablePlanApi(enablePlanApi);
          setEnableBillFetchApi(enableBillFetchApi);
          setOriginalFormData({
            serviceName,
            serviceIcon,
            serviceAppIcon:
              serviceAppIcon && serviceAppIcon !== 'null' ? serviceAppIcon : '',
            surcharge,
            defaultMasterDistributorsShare,
            defaultDistributorsShare,
            defaultRetailersShare,
            defaultApiUsersShare,
            serviceStatus,
            enablePlanApi,
            enableBillFetchApi,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      serviceName,
      serviceIcon: serviceIconBase64,
      serviceAppIcon:
        serviceAppIconBase64 &&
        serviceAppIconBase64 !== 'null' &&
        serviceAppIconBase64 !== null
          ? serviceAppIconBase64
          : '',
      surcharge,
      defaultMasterDistributorsShare,
      defaultDistributorsShare,
      defaultRetailersShare,
      defaultApiUsersShare,
      serviceStatus,
      enablePlanApi,
      enableBillFetchApi,
    };
    API.put(`/recharge-configurations/services/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (serviceIcon?.length > 0) {
      getBase64(serviceIcon[0], setServiceIconBase64);
    }
  }, [serviceIcon]);

  useEffect(() => {
    if (serviceAppIcon?.length > 0) {
      getBase64(serviceAppIcon[0], setServiceAppIconBase64);
    }
  }, [serviceAppIcon]);

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            <Label required>Service Name</Label>
            <TextInput
              value={serviceName}
              onChange={setServiceName}
              placeholder="Please enter service name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Service Icon</Label>
            <FileInput
              files={serviceIcon}
              onChange={setServiceIcon}
              accept="image/*"
              disabled={isLoading === true}
            />
            {originalFormData?.serviceIcon && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowImage(1);
                  setShowExistingImage(true);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>

          <Fieldset>
            <Label>Service APP Icon</Label>
            <FileInput
              files={serviceAppIcon}
              onChange={setServiceAppIcon}
              accept="image/*"
              disabled={isLoading === true}
            />
            {originalFormData?.serviceAppIcon && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowImage(2);
                  setShowExistingImage(true);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>

          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Surcharge</Label>
                <NumberInput
                  value={surcharge}
                  onChange={setSurcharge}
                  placeholder="Please enter surcharge"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Master Distributors Commission</Label>
                <NumberInput
                  value={defaultMasterDistributorsShare}
                  onChange={setDefaultMasterDistributorsShare}
                  placeholder="Please enter master distributors commission"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Distributors Commission</Label>
                <NumberInput
                  value={defaultDistributorsShare}
                  onChange={setDefaultDistributorsShare}
                  placeholder="Please enter distributors commission"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Retailers Commission</Label>
                <NumberInput
                  value={defaultRetailersShare}
                  onChange={setDefaultRetailersShare}
                  placeholder="Please enter retailers commission"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Users Commission</Label>
                <NumberInput
                  value={defaultApiUsersShare}
                  onChange={setDefaultApiUsersShare}
                  placeholder="Please enter api users commission"
                  maxLength={100}
                  disabled={isLoading === true}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Enable Plan API</Label>
                <Dropdown
                  placeholder="Please select plan api status"
                  value={enablePlanApi}
                  onChange={setEnablePlanApi}
                  options={[
                    {
                      title: '-- SELECT PLAN API STATUS --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Enable Bill Fetch API</Label>
                <Dropdown
                  placeholder="Please select bill fetch api status"
                  value={enableBillFetchApi}
                  onChange={setEnableBillFetchApi}
                  options={[
                    {
                      title: '-- SELECT BILL FETCH API STATUS --',
                      value: '',
                    },
                    {
                      title: 'Yes',
                      value: 'Yes',
                    },
                    {
                      title: 'No',
                      value: 'No',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Service Status</Label>
                <Dropdown
                  placeholder="Please select service status"
                  value={serviceStatus}
                  onChange={setServiceStatus}
                  options={[
                    {
                      title: '-- SELECT SERVICE STATUS --',
                      value: '',
                    },
                    {
                      title: 'Active',
                      value: 'Active',
                    },
                    {
                      title: 'Inactive',
                      value: 'Inactive',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading === true} type="submit">
              Update
            </UpdateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image
            width={150}
            source={
              showImage === 1
                ? originalFormData?.serviceIcon
                : originalFormData?.serviceAppIcon
            }
            alt={serviceName}
          />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(ServicesUpdate);
