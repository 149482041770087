import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { isArray, isEmpty } from "lodash";
import { withAppHOC } from "../../hoc";
import { Image } from "../../components/Image";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import API from "../../api";
import { ACCOUNT_TYPES, API_RESPONSE_TYPES } from "../../constants";
import {
  Dropdown,
  Fieldset,
  Form,
  FormContainer,
  Label,
  NumberInput,
  TextInput,
  MessageBox,
  ResetButton,
  CustomConfirmButton,
  Button,
} from "../../components/FormElements";
import { StyledSpan, StyledSpanAsButton } from "../../components/Styled";
import { authData, camelCaseToString, formatCurrency } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faMoneyBill1Wave,
} from "@fortawesome/free-solid-svg-icons";
import { TableBuilder } from "../../components/TableElements";
import StatusHighliter from "../../components/StatusHighliter/StatusHighliter";

const StyledServices = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  margin: 20px auto;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-bottom: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledService = styled.section`
  width: calc(100% / ${({ itemsCount }) => itemsCount || 10} - 10px);
  height: 80px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 20px 0px;
  padding: 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.5s, border-right 0.5s, color 0.5s;
  @media (max-width: 600px) {
    width: 250px;
    padding: 20px;
    margin-right: 20px;
  }
  &:hover {
    background-color: ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)};
    color: #fff;
  }
  &.active {
    background-color: ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)};
    color: #fff;
  }
`;

const StyledServiceIcon = styled.section`
  width: 50px;
  height: 50px;
`;

const StyledServiceName = styled.section`
  font-size: 13px;
  font-weight: normal;
  padding: 5px 0px 10px 0px;
  margin: 0px;
  text-align: center;
  @media (max-width: 600px) {
    width: 90px;
  }
`;

const StyledRechargeArea = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledRechargeForm = styled.section`
  width: 50%;
  max-width: 600px;
  height: auto;
  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const StyledPlanData = styled.section`
  width: calc(100% - 50%);
  min-width: calc(100% - 620px);
  height: auto;
  border-radius: 10px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  align-self: center;
`;

const StyledPlansContainer = styled.section`
  width: 100%;
  height: auto;
  max-height: 442px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 0px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  overflow-y: scroll;
`;

const StyledStatsContainerOuter = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledStatsContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const StyledStatsSection = styled.section`
  width: 300px;
  height: 100px;
  background: linear-gradient(
    to top right,
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { secondaryColor },
      },
    }) => (secondaryColor ? secondaryColor : `#0078ce`)},
    ${({
      theme: {
        colors: { primaryColor },
      },
    }) => (primaryColor ? primaryColor : `#0095ff`)}
  );
  padding: 0px;
  margin: 0px;
  display: grid;
  border-radius: 10px;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const StyledStatsIcon = styled.section`
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  i {
    width: 30px;
    height: 30px;
    color: #ffffff;
    border: 5px solid #ffffff;
    padding: 15px;
    border-radius: 100px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledStatsCounter = styled.section`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
`;

const StyledStatsHeading = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0px;
  padding: 15px;
  text-align: left;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledStatsCount = styled.h3`
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  padding: 18px 15px 0px 15px;
`;

const StyledTransactionMessageContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.Success {
    color: forestgreen;
  }
  &.Failure {
    color: red;
  }
  &.Pending {
    color: #0095ff;
  }
  svg {
    font-size: 60px;
  }
  h2 {
    font-size: 26px;
    font-weight: normal;
    margin: 0px;
    padding: 20px;
  }
  p {
    color: #545454;
  }
`;

const StyledTabsContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
`;

const StyledSearchContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  border-radius: 5px;
`;

const StyledTabButton = styled(Button)`
  width: 100%;
  border-radius: 0px;
  padding: 12.5px;
  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  &:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  &.active {
    background-color: #003f6c;
  }
`;

const StyledDescription = styled.p`
  width: 100%;
  word-break: break-all;
  padding: 0px;
  margin: 0px;
  white-space: normal;
`;

const StyledAmountButton = styled(Button)`
  width: 100%;
`;

const Dashboard = (props) => {
  const { accountType } = authData.get();
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [services, setServices] = useState([]);
  const [operators, setOperators] = useState([]);
  const [circles, setCircles] = useState([]);
  const [plansData, setPlansData] = useState([]);
  const [roffersData, setRoffersData] = useState([]);
  const [accountsInfo, setAccountsInfo] = useState("");
  const [activeService, setActiveService] = useState("");
  const [activeServiceName, setActiveServiceName] = useState("");
  const [enablePlanApi, setEnablePlanApi] = useState("No");
  const [enableBillFetchApi, setEnableBillFetchApi] = useState("No");
  const [number, setNumber] = useState("");
  const [operatorsId, setOperatorsId] = useState("");
  const [circlesId, setCirclesId] = useState("");
  const [amount, setAmount] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [field1Value, setField1Value] = useState("");
  const [field2Value, setField2Value] = useState("");
  const [field3Value, setField3Value] = useState("");
  const [field4Value, setField4Value] = useState("");
  const [stats, setStats] = useState(null);
  const [tab, setTab] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [transactions, setTransactions] = useState([]);

  const [transactionStatus, setTransactionStatus] = useState("");

  const { homeBanner } = props.appData;

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setNumber("");
    setOperatorsId("");
    setCirclesId("");
    setAmount("");
    setPlansData([]);
    setRoffersData([]);
  };

  const readServices = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/services")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setServices(
            data.filter(({ serviceStatus }) => serviceStatus === "Active")
          );
          if (data?.length > 0) {
            const { id, serviceName, enablePlanApi, enableBillFetchApi } =
              data[0];
            setActiveService(id);
            setActiveServiceName(serviceName);
            setEnablePlanApi(enablePlanApi);
            setEnableBillFetchApi(enableBillFetchApi);
          }
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readOperators = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/operators")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOperators(data);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readCircles = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/circles")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setCircles(data);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readPlanApiData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      `/business-process/fetch-plans/?number=${number}&servicesId=${activeService}&operatorsId=${operatorsId}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (activeService === 1) {
            if (data.operatorsId) {
              setOperatorsId(data.operatorsId);
            }
            if (data.circlesId) {
              setCirclesId(data.circlesId);
            }
          }
          if (activeService === 2) {
            setAccountsInfo(data?.accountInfo);
          }
          setTab("plans");
          setPlansData(data?.plans);
          setRoffersData(data?.roffers);
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [activeService, number, operatorsId]);

  const fetchBill = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");

    if (!number || !operatorsId) {
      setIsLoading(false);
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(
        `Please enter ${
          activeService === 4 ? `mobile` : `account`
        } number & select operator`
      );
    } else {
      API.get(
        `/business-process/fetch-bill/?number=${number}&servicesId=${activeService}&operatorsId=${operatorsId}&field1=${field1Value}&field2=${field2Value}&field3=${field3Value}&field4=${field4Value}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setAccountsInfo(data);
            if (data?.billamount || data.billamount === 0) {
              setAmount(data.billamount);
            }
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    activeService,
    number,
    operatorsId,
    field1Value,
    field2Value,
    field3Value,
    field4Value,
  ]);

  const heavyRefresh = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    if (!number || !operatorsId) {
      setIsLoading(false);
      setResponseStatus(API_RESPONSE_TYPES.FAILURE);
      setResponseMessage(
        `Please enter ${
          activeService === 4 ? `mobile` : `account`
        } number & select operator`
      );
    } else {
      API.get(
        `/business-process/dth-heavy-refresh/?number=${number}&servicesId=${activeService}&operatorsId=${operatorsId}&field1=${field1Value}&field2=${field2Value}&field3=${field3Value}&field4=${field4Value}`
      )
        .then((response) => {
          const { status, message } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    setTransactionStatus("");
    const formData = {
      number: number,
      operator: operatorsId,
      circle: circlesId,
      amount: amount,
      field1: field1Value,
      field2: field2Value,
      field3: field3Value,
      field4: field4Value,
    };
    API.post("/business-process/recharge", formData)
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (
          !isArray(data) &&
          ["Success", "Failure", "Pending"].includes(data)
        ) {
          setTransactionStatus(data);
        }
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setPlansData([]);
          setRoffersData([]);
          resetForm();
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        setTimeout(() => {
          setResponseStatus("");
          setResponseMessage("");
          setTransactionStatus("");
        }, 3000);
      });
  };

  const readStats = useCallback(() => {
    if (
      ![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
      ].includes(accountType)
    ) {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get("/common/stats")
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            const stats = [];
            for (const property in data) {
              if (
                property.indexOf("Color") !== -1 ||
                property.indexOf("Icon") !== -1
              ) {
                continue;
              } else {
                const colorKey = `${property}Color`;
                const iconKey = `${property}Icon`;
                stats.push({
                  title: property,
                  processedTitle: camelCaseToString(
                    property.indexOf("_") !== -1
                      ? property.split("_")[1]
                      : property
                  ),
                  value: data[property],
                  color: data[colorKey],
                  icon: data[iconKey],
                });
              }
            }
            setStats(stats);
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [accountType]);

  const readTransactions = () => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(
      `/transactions/?transactionsId=&pageNumber=1&recordsPerPage=6&faccountsId=&number=&operatorsId=&apiProvidersId=&startDate=&endDate=&transactionsStatus=&disputeStatus=`
    )
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setTransactions(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      [
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
      ].includes(accountType)
    ) {
      readServices();
      readOperators();
      readCircles();
      readTransactions();
    }
    readStats();
  }, [accountType, readStats]);

  useEffect(() => {
    if (operatorsId && operatorsId !== "") {
      const { field1, field2, field3, field4 } = operators.find(
        ({ id }) => id === operatorsId
      );
      setField1(field1);
      setField2(field2);
      setField3(field3);
      setField4(field4);
      setField1Value("");
      setField2Value("");
      setField3Value("");
      setField4Value("");
    } else {
      setField1("");
      setField2("");
      setField3("");
      setField4("");
      setField1Value("");
      setField2Value("");
      setField3Value("");
      setField4Value("");
    }
  }, [operatorsId, operators]);

  useEffect(() => {
    if (activeService === 1) {
      if (number && number?.length === 10 && enablePlanApi === "Yes") {
        readPlanApiData();
      }
    } else if (activeService === 2) {
      if (operatorsId !== "" && number !== "") {
        readPlanApiData();
      }
    }
  }, [
    number,
    operatorsId,
    activeService,
    enablePlanApi,
    readPlanApiData,
    fetchBill,
  ]);

  const operatorOptions = [{ title: "-- SELECT OPERATOR --", value: "" }];
  const circleOptions = [{ title: "-- SELECT CIRCLE --", value: "" }];

  if (!isEmpty(operators)) {
    operators
      .filter(({ servicesId }) => activeService === servicesId)
      .forEach(({ id, operatorName }) =>
        operatorOptions.push({
          title: operatorName,
          value: id,
        })
      );
  }

  if (!isEmpty(circles)) {
    circles.forEach(({ id, circleName }) =>
      circleOptions.push({
        title: circleName,
        value: id,
      })
    );
  }

  return (
    <>
      {[
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
      ].includes(accountType) && (
        <>
          <StyledServices>
            {services &&
              services?.length > 0 &&
              services.map(
                ({
                  id,
                  serviceName,
                  serviceIcon,
                  enablePlanApi,
                  enableBillFetchApi,
                }) => (
                  <StyledService
                    itemsCount={services?.length}
                    key={id}
                    className={id === activeService ? `active` : ``}
                    onClick={() => {
                      resetForm();
                      resetResponseData();
                      setAccountsInfo("");
                      setPlansData([]);
                      setRoffersData([]);
                      setActiveService(id);
                      setActiveServiceName(serviceName);
                      setEnablePlanApi(enablePlanApi);
                      setEnableBillFetchApi(enableBillFetchApi);
                    }}
                  >
                    <StyledServiceIcon>
                      <Image
                        source={serviceIcon ? serviceIcon : "/no-image.png"}
                        alt={serviceName}
                      />
                    </StyledServiceIcon>
                    <StyledServiceName>{serviceName}</StyledServiceName>
                  </StyledService>
                )
              )}
          </StyledServices>
          <StyledRechargeArea>
            <StyledRechargeForm>
              <StyledHeading>{activeServiceName}</StyledHeading>
              <FormContainer>
                <Form method="POST" onSubmit={(e) => e.preventDefault()}>
                  <Fieldset>
                    <Label required>
                      {activeService === 1 || activeService === 4
                        ? `Mobile Number`
                        : `Account Number`}
                    </Label>
                    <TextInput
                      value={number}
                      onChange={(value) => {
                        if (activeService === 1 || activeService === 4) {
                          if (value && value.length !== 10) {
                            setOperatorsId("");
                            setCirclesId("");
                            setPlansData([]);
                            setRoffersData([]);
                            setNumber(value);
                          } else {
                            setNumber(value);
                          }
                        } else {
                          setNumber(value);
                        }
                      }}
                      placeholder={
                        activeService === 1 || activeService === 4
                          ? `Please enter mobile number`
                          : `Please enter account Number`
                      }
                      maxLength={
                        activeService === 1 || activeService === 4 ? 10 : 100
                      }
                      disabled={isLoading}
                    />
                  </Fieldset>
                  <Fieldset>
                    <Label required>Operator</Label>
                    <Dropdown
                      placeholder="Please select operator"
                      value={operatorsId}
                      onChange={setOperatorsId}
                      options={operatorOptions}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  {activeService === 1 && (
                    <Fieldset>
                      <Label required>Circle</Label>
                      <Dropdown
                        placeholder="Please select circle"
                        value={circlesId}
                        onChange={setCirclesId}
                        options={circleOptions}
                        disabled={isLoading}
                      />
                    </Fieldset>
                  )}
                  {field1 && (
                    <Fieldset>
                      <Label required>{field1}</Label>
                      <TextInput
                        value={field1Value}
                        onChange={setField1Value}
                        placeholder={`Please enter ${field1}`}
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                  )}
                  {field2 && (
                    <Fieldset>
                      <Label required>{field2}</Label>
                      <TextInput
                        value={field2Value}
                        onChange={setField2Value}
                        placeholder={`Please enter ${field2}`}
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                  )}
                  {field3 && (
                    <Fieldset>
                      <Label required>{field3}</Label>
                      <TextInput
                        value={field3Value}
                        onChange={setField3Value}
                        placeholder={`Please enter ${field3}`}
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                  )}
                  {field4 && (
                    <Fieldset>
                      <Label required>{field4}</Label>
                      <TextInput
                        value={field4Value}
                        onChange={setField4Value}
                        placeholder={`Please enter ${field4}`}
                        disabled={isLoading === true}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <Label required>Amount</Label>
                    {activeService === 2 && (
                      <StyledSpanAsButton
                        onClick={heavyRefresh}
                        disabled={isEmpty(number) || isEmpty(operatorsId)}
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        Heavy Refresh
                      </StyledSpanAsButton>
                    )}
                    {enableBillFetchApi === "Yes" && (
                      <StyledSpanAsButton
                        onClick={fetchBill}
                        disabled={isEmpty(number) || isEmpty(operatorsId)}
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        Fetch Bill
                      </StyledSpanAsButton>
                    )}
                    <NumberInput
                      value={amount}
                      onChange={setAmount}
                      placeholder="Please enter amount"
                      maxLength={100}
                      disabled={isLoading === true}
                      pattern={`[0-9.]*`}
                    />
                  </Fieldset>
                  {accountsInfo !== "" && (
                    <Fieldset>
                      {accountsInfo.name && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Name :</b> {accountsInfo.name}
                        </StyledSpan>
                      )}
                      {accountsInfo.registeredMobileNumber && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Registered Mobile Number :</b>{" "}
                          {accountsInfo.registeredMobileNumber}
                        </StyledSpan>
                      )}
                      {accountsInfo.plan &&
                        typeof accountsInfo.plan === "string" && (
                          <StyledSpan style={{ color: "#000000" }}>
                            <b>Plan :</b> {accountsInfo.plan}
                          </StyledSpan>
                        )}
                      {accountsInfo.customername && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Customer Name :</b> {accountsInfo.customername}
                        </StyledSpan>
                      )}
                      {accountsInfo.billnumber && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Bill Number :</b> {accountsInfo.billnumber}
                        </StyledSpan>
                      )}
                      {accountsInfo.billdate && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Bill Date :</b> {accountsInfo.billdate}
                        </StyledSpan>
                      )}
                      {accountsInfo.duedate && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Due Date :</b> {accountsInfo.duedate}
                        </StyledSpan>
                      )}
                      {accountsInfo.monthlyRecharge && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Monthly Recharge :</b>{" "}
                          {formatCurrency(accountsInfo.monthlyRecharge)}
                        </StyledSpan>
                      )}
                      {accountsInfo.balance && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Balance :</b>{" "}
                          {formatCurrency(accountsInfo.balance)}
                        </StyledSpan>
                      )}
                      {accountsInfo.nextRechargeDay && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Next Recharge Date :</b>{" "}
                          {accountsInfo.nextRechargeDay}
                        </StyledSpan>
                      )}
                      {accountsInfo.boxStatus && (
                        <StyledSpan style={{ color: "#000000" }}>
                          <b>Connection Status :</b> {accountsInfo.boxStatus}
                        </StyledSpan>
                      )}
                    </Fieldset>
                  )}
                  {transactionStatus === "" && responseStatus && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CustomConfirmButton
                      disabled={
                        isLoading === true ||
                        number === "" ||
                        operatorsId === "" ||
                        amount === ""
                      }
                      icon={faMoneyBill1Wave}
                      showTitle={true}
                      showIcon={false}
                      showTextButton={true}
                      title={
                        activeService === 1 || activeService === 2
                          ? `Recharge`
                          : `Bill Payment`
                      }
                      buttonTitle="Submit"
                      message={
                        activeService === 1 || activeService === 2
                          ? `Are you sure, you want to recharge ${number} with ${formatCurrency(
                              amount
                            )} ?`
                          : `Are you sure, you want to pay bill for ${number} with ${formatCurrency(
                              amount
                            )} ?`
                      }
                      proceedButtonText="Submit"
                      cancelButtonText="Cancel"
                      onClick={onSubmit}
                      color="forestgreen"
                      hoverColor="#006200"
                    />

                    <ResetButton
                      disabled={isLoading === true}
                      type="button"
                      onClick={resetForm}
                    >
                      Reset
                    </ResetButton>
                  </Fieldset>
                </Form>
                <OverlayLoader showLoader={isLoading} />
              </FormContainer>
            </StyledRechargeForm>
            <StyledPlanData>
              {transactionStatus !== "" ? (
                <>
                  <StyledTransactionMessageContainer
                    className={transactionStatus}
                  >
                    <FontAwesomeIcon
                      icon={
                        transactionStatus === "Success"
                          ? faCircleCheck
                          : transactionStatus === "Pending"
                          ? faCircleExclamation
                          : faCircleXmark
                      }
                    />
                    <h2>Transaction {transactionStatus}</h2>
                    <p>{responseMessage}</p>
                  </StyledTransactionMessageContainer>
                </>
              ) : isEmpty(plansData) && isEmpty(roffersData) ? (
                <>
                  {transactions && transactions.length > 0 ? (
                    <>
                    <StyledHeading>Recent Transactions</StyledHeading>
                    <TableBuilder
                      isLoading={transactions?.length !== 0 && isLoading}
                      tableHeadings={[
                        {
                          title: "Operator",
                          dataSelector: "operatorName",
                          dataType: "string",
                        },
                        {
                          title: "Mobile/Account Number",
                          dataSelector: "rechargeNumber",
                          dataType: "string",
                        },
                        {
                          title: "Amount",
                          dataSelector: "amount",
                          dataType: "number",
                          align: "center",
                          CellRenderer: (value) => formatCurrency(value),
                        },

                        {
                          title: "Transaction Status",
                          dataSelector: "processedStatus",
                          dataType: "string",
                          align: "center",
                          CellRenderer: (value) =>
                            value && (
                              <StatusHighliter
                                className={
                                  value === "Success"
                                    ? "green"
                                    : value === "Failure"
                                    ? "red"
                                    : value === "Pending"
                                    ? "yellow"
                                    : "blue"
                                }
                              >
                                {value}
                              </StatusHighliter>
                            ),
                        },
                      ]}
                      tableData={transactions}
                    />
                    </>
                  ) : (
                    <Image
                      source={
                        homeBanner === "" || homeBanner === null
                          ? "home-banner.png"
                          : homeBanner
                      }
                      alt="Home Banner"
                    />
                  )}
                </>
              ) : (
                <>
                  <StyledHeading>Plans &amp; Offers</StyledHeading>
                  <StyledTabsContainer>
                    <StyledTabButton
                      onClick={() => {
                        setSearchQuery("");
                        setTab("plans");
                      }}
                      className={tab === "plans" ? "active" : ""}
                    >
                      Operator Plans
                    </StyledTabButton>
                    <StyledTabButton
                      onClick={() => {
                        setSearchQuery("");
                        setTab("roffers");
                      }}
                      className={tab === "roffers" ? "active" : ""}
                    >
                      {activeService === 2 ? `Add-On Packs` : `Special Offers`}
                    </StyledTabButton>
                  </StyledTabsContainer>
                  <StyledSearchContainer>
                    <TextInput
                      style={{ width: "100%" }}
                      type="search"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={setSearchQuery}
                    />
                  </StyledSearchContainer>
                  <StyledPlansContainer>
                    {tab === "plans" && (
                      <TableBuilder
                        tableHeadings={[
                          {
                            title: "Description",
                            dataSelector: "description",
                            dataType: "string",
                            CellRenderer: (value) => (
                              <StyledDescription>{value}</StyledDescription>
                            ),
                          },
                          {
                            title: "Amount",
                            dataSelector: "amount",
                            dataType: "string",
                            CellRenderer: (value) => (
                              <StyledAmountButton
                                onClick={() => setAmount(value)}
                              >
                                {formatCurrency(value)}
                              </StyledAmountButton>
                            ),
                          },
                          {
                            title: "Validity",
                            dataSelector: "validity",
                            dataType: "string",
                          },
                        ]}
                        tableData={
                          !isEmpty(searchQuery)
                            ? plansData.filter(
                                (item) =>
                                  item.description
                                    ?.toString()
                                    ?.includes(searchQuery) ||
                                  item.validity
                                    ?.toString()
                                    ?.includes(searchQuery) ||
                                  item.amount?.toString()?.includes(searchQuery)
                              )
                            : plansData
                        }
                      />
                    )}
                    {tab === "roffers" && (
                      <TableBuilder
                        tableHeadings={[
                          {
                            title: "Description",
                            dataSelector: "description",
                            dataType: "string",
                            CellRenderer: (value) => (
                              <StyledDescription>{value}</StyledDescription>
                            ),
                          },
                          {
                            title: "Amount",
                            dataSelector: "amount",
                            dataType: "string",
                            CellRenderer: (value) => (
                              <StyledAmountButton
                                onClick={() => setAmount(value)}
                              >
                                {formatCurrency(value)}
                              </StyledAmountButton>
                            ),
                          },
                          {
                            title: "Validity",
                            dataSelector: "validity",
                            dataType: "string",
                          },
                        ]}
                        tableData={
                          !isEmpty(searchQuery)
                            ? roffersData.filter(
                                (item) =>
                                  item.description
                                    ?.toString()
                                    ?.includes(searchQuery) ||
                                  item.validity
                                    ?.toString()
                                    ?.includes(searchQuery) ||
                                  item.amount?.toString()?.includes(searchQuery)
                              )
                            : roffersData
                        }
                      />
                    )}
                  </StyledPlansContainer>
                </>
              )}
            </StyledPlanData>
          </StyledRechargeArea>
        </>
      )}
      {![
        ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
        ACCOUNT_TYPES.DISTRIBUTORS,
        ACCOUNT_TYPES.RETAILERS,
      ].includes(accountType) &&
        stats &&
        stats?.length !== 0 && (
          <StyledStatsContainerOuter>
            <StyledStatsContainer>
              {stats.map(({ value, icon, processedTitle }, index) => (
                <StyledStatsSection key={index}>
                  <StyledStatsIcon>
                    <i className={icon}></i>
                  </StyledStatsIcon>
                  <StyledStatsCounter>
                    <StyledStatsCount>{value}</StyledStatsCount>
                    <StyledStatsHeading title={processedTitle}>
                      {processedTitle}
                    </StyledStatsHeading>
                  </StyledStatsCounter>
                </StyledStatsSection>
              ))}
            </StyledStatsContainer>
          </StyledStatsContainerOuter>
        )}
    </>
  );
};

export default withAppHOC(Dashboard);
