import React, { useState, useEffect, useCallback } from "react";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  TextInput,
  Label,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import { StyledFiltersContainer } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES } from "../../../constants";
import { formatCurrency } from "../../../utils";
import moment from "moment";

const APISummaryBookTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [daybookData, setDaybookData] = useState([]);
  const [apiProviders, setApiProviders] = useState([]);
  const [apiProvidersId, setApiProvidersId] = useState("");
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const resetForm = () => {
    setApiProvidersId("");
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    readData("", moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
  };

  const readApiProviders = () => {
    API.get("/recharge-configurations/api-providers").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setApiProviders(data);
      }
    });
  };

  const readData = useCallback(
    (apiProvidersId = "", startDate = "", endDate = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/reports/api-summary-book/?apiProvidersId=${apiProvidersId}&startDate=${startDate}&endDate=${endDate}`
      )
        .then((response) => {
          const { status, message, data } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          }
          setDaybookData(data);
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    []
  );

  const filterData = (e) => {
    e.preventDefault();
    readData(apiProvidersId, startDate, endDate);
  };

  useEffect(() => {
    readApiProviders();
    readData(apiProvidersId, startDate, endDate);
  }, []);

  const apiProvidersOptions = [
    { title: "-- SELECT API PROVIDER --", value: "" },
  ];
  apiProviders.forEach(({ id, providerName }) =>
    apiProvidersOptions.push({
      title: providerName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} canReloadProp={false} />
      <StyledFiltersContainer className="show">
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>API Provider</Label>
            <Dropdown
              style={{ border: "1px solid #cacaca" }}
              placeholder="API Provider"
              value={apiProvidersId}
              onChange={setApiProvidersId}
              options={apiProvidersOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Start Date</Label>
            <TextInput
              type="date"
              value={startDate}
              onChange={setStartDate}
              placeholder="Start Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Label>End Date</Label>
            <TextInput
              type="date"
              value={endDate}
              onChange={setEndDate}
              placeholder="End Date"
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Search
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && daybookData?.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          daybookData?.length === 0
        }
        message={`No data found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {daybookData?.length > 0 && (
        <TableBuilder
          isLoading={daybookData?.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: "API Provider",
              dataSelector: "providerName",
              dataType: "string",
            },
            {
              title: "Opening Balance",
              dataSelector: "openingBalance",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Refunds Amount",
              dataSelector: "refundsAmount",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Purchase",
              dataSelector: "totalPurchase",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Sale",
              dataSelector: "totalSale",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Commission",
              dataSelector: "totalCommission",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Expected Closing Balance",
              dataSelector: "expectedClosingBalance",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Actual Closing Balance",
              dataSelector: "actualClosingBalance",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
            {
              title: "Difference",
              dataSelector: "differenceonClosings",
              dataType: "number",
              CellRenderer: (value) => formatCurrency(value),
            },
          ]}
          tableData={daybookData}
          tableFooter={[
            {
              value: "",
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.openingBalance),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.refundsAmount),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.totalPurchase),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.totalSale),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.totalCommission),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.expectedClosingBalance),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.actualClosingBalance),
                  0
                )
              ),
            },
            {
              value: formatCurrency(
                daybookData.reduce(
                  (previousValue, currentValue) =>
                    previousValue + Number(currentValue.differenceonClosings),
                  0
                )
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default withAppHOC(APISummaryBookTable);
