import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
  CustomButton,
  Dropdown,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import {
  StyledLink,
  StyledSpan,
  StyledFiltersContainer,
  StyledModalContainer,
  StyledIconTitleContainer,
  StyledTitleContainer,
  StyledIconContainer,
} from "../../../components/Styled";
import Pagination from "../../../components/Pagination/Pagination";
import API from "../../../api";
import {
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
  ACCOUNT_TYPES,
} from "../../../constants";
import {
  camelCaseToString,
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
  authData,
} from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import Modal from "../../../components/Modal";
import { Image } from "../../../components/Image";

const StyledTemplateMarginsReadContainer = styled.section`
  max-height: 400px;
  overflow-y: scroll;
  padding: 20px;
`;

const StyledTemplateName = styled.h2`
  font-size: 16px;
  margin: 0;
  padding: 20px 20px 0px;
`;

const MarginTemplatesTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [marginTemplates, setMarginTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTemplateName, setModalTemplateName] = useState("");
  const [modalTemplateData, setModalTemplateData] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setAccountsId] = useState("");
  const { accountType } = authData.get();

  const resetForm = () => {
    setAccountsId("");
    setTemplateName("");
    readData();
  };

  const readData = useCallback(
    (accountsId = "", templateName = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/margins/margin-templates/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fTemplateName=${templateName}`
      )
        .then((response) => {
          const { status, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setTotalRecords(pageInfo.totalRecords);
            }
            setMarginTemplates(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(accountsId, templateName);
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/margins/margin-templates/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fAccountsId=${accountsId}&fTemplateName=${templateName}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            setMarginTemplates(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const readAccounts = () => {
    API.get("/accounts-management/accounts/?files=No").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        if (data?.length > 0) {
          setAccounts(data);
        }
      }
    });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(accountsId, templateName);
    }
  }, [paginationData, readData]);

  useEffect(() => {
    readAccounts();
  }, []);

  useEffect(() => {
    if (
      accountType !== ACCOUNT_TYPES.MASTER_DISTRIBUTORS &&
      accountType !== ACCOUNT_TYPES.DISTRIBUTORS
    ) {
      readAccounts();
    }
  }, [accountType]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const accountsOptions = [{ title: "-- SELECT ACCOUNT --", value: "" }];
  accounts
    .filter((account) => account?.isDeleted === "No" && [ACCOUNT_TYPES.MASTER_DISTRIBUTORS, ACCOUNT_TYPES.DISTRIBUTORS].includes(account.roleName))
    .forEach(({ id, isEntity, firstName, lastName, entityName, roleName }) =>
      accountsOptions.push({
        title:
          isEntity === "Yes"
            ? `${entityName} (${camelCaseToString(roleName)})`
            : `${firstName} ${lastName} (${camelCaseToString(roleName)})`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          {[ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(
            accountType
          ) && (
            <Fieldset>
              <Label>Account</Label>
              <Dropdown
                style={{ border: "1px solid #cacaca" }}
                placeholder="Account"
                value={accountsId}
                onChange={setAccountsId}
                options={accountsOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label>Template Name</Label>
            <TextInput
              value={templateName}
              onChange={setTemplateName}
              placeholder="Template Name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && marginTemplates?.length === 0 && isLoading
        }
      />
      <NoData
        status={!isLoading && marginTemplates?.length === 0}
        message={`No margin templates found`}
      />
      <MessageBox status={responseStatus} message={responseMessage} />
      {marginTemplates?.length > 0 && (
        <>
          <TableBuilder
            isLoading={marginTemplates?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {item.isDefaultTemplate === "Yes" && (
                      <CustomButton
                        icon={faEye}
                        onClick={() => {
                          setModalTemplateName(item?.templateName);
                          setModalTemplateData(
                            typeof item?.template === "string"
                              ? JSON.parse(item.template)
                              : item.template
                          );
                          setShowModal(true);
                        }}
                        style={{ color: "#0095ff" }}
                      />
                    )}
                    {canUpdate && item.isDefaultTemplate === "No" && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && item.isDefaultTemplate === "No" && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: "Template Name",
                dataSelector: "templateName",
                dataType: "string",
              },
              {
                title: "Created By",
                dataSelector: "firstName",
                dataType: "string",
                requiredRoles: [ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF],
                CellRenderer: (value, item) => {
                  const { lastName, roleName, entityName } = item;
                  return (
                    <>
                      {entityName && entityName !== "null"
                        ? entityName
                        : `${value} ${lastName}`}
                      {roleName && (
                        <StyledSpan>{camelCaseToString(roleName)}</StyledSpan>
                      )}
                    </>
                  );
                },
              },
            ]}
            tableData={marginTemplates}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              setShowModal(false);
              setModalTemplateName("");
              setModalTemplateData("");
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"800px"}>
              <h3>Template Margins</h3>
              <StyledTemplateName>
                Template Name : {modalTemplateName}
              </StyledTemplateName>
              <StyledTemplateMarginsReadContainer>
                <TableBuilder
                  isLoading={modalTemplateData?.length !== 0 && isLoading}
                  tableHeadings={[
                    {
                      title: "Operator",
                      dataSelector: "operatorName",
                      dataType: "string",
                      width: 500,
                      CellRenderer: (value, item) => (
                        <StyledIconTitleContainer>
                          <StyledIconContainer
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            <Image
                              source={
                                item.operatorLogo
                                  ? item.operatorLogo
                                  : "/no-image.png"
                              }
                              alt={value}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </StyledIconContainer>
                          <StyledTitleContainer>
                            {value}
                            <StyledSpan>{item.serviceName}</StyledSpan>
                          </StyledTitleContainer>
                        </StyledIconTitleContainer>
                      ),
                    },
                    {
                      title: "Commission",
                      dataSelector: "commission",
                      dataType: "number",
                      align: "center",
                      CellRenderer: (value, item) => {
                        if (item.surcharge === 0) {
                          return item?.commissionType === "Percentage"
                            ? `${value ? value : 0}%`
                            : formatCurrency(value);
                        } else {
                        }
                      },
                    },
                    {
                      title: "Commission Type",
                      dataSelector: "commissionType",
                      dataType: "string",
                      CellRenderer: (value, item) =>
                        item.surcharge === 0 ? value : "Surcharge",
                    },
                  ]}
                  tableData={modalTemplateData}
                />
              </StyledTemplateMarginsReadContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(MarginTemplatesTable);
