import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Image } from '../../../components/Image';
import {
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  ResetButton,
  Label,
  FormContainer,
  NumberInput,
  Dropdown,
  CreateButton,
} from '../../../components/FormElements';
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { useCallback } from 'react';
import { formatCurrency } from '../../../utils';

const StyledModuleContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 1200px) {
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    label {
      margin: 10px 0px;
    }
  }
`;

const StyledModuleNameContainer = styled.section`
  width: auto;
  min-width: 25%;
  padding: 0px 10px;
  @media (max-width: 1200px) {
    padding: 0px;
    width: 100%;
  }
`;

const StyledModuleCommissionContainer = styled.section`
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #e5e5e5;
  @media (max-width: 1200px) {
    padding: 10px 0px;
    width: 100%;
    border: 0px;
    display: block;
  }
`;

const APIProvidersOperatorsMapping = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [template, setTemplate] = useState([]);
  const [originalTemplate, setOriginalTemplate] = useState([]);
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setTemplate(originalTemplate);
  };

  const generateTemplate = useCallback(
    (operatorMappings) => {
      const processedOperatorMappings =
        typeof operatorMappings === 'string'
          ? JSON.parse(operatorMappings)
          : operatorMappings;

      const newTemplate = operators?.map((item) => {
        const {
          id: operatorsId,
          operatorName,
          operatorLogo,
          serviceName,
          surcharge,
          isRange = 'N',
          fromAmount,
          toAmount,
          rangesId,
        } = item;
        const item2 =
          processedOperatorMappings?.length > 0 &&
          processedOperatorMappings?.find((item3) => {
            return (
              parseInt(item3?.operatorsId) === parseInt(operatorsId) &&
              item3?.fromAmount === fromAmount &&
              item3?.toAmount === toAmount
            );
          });

        const operatorCode = item2?.operatorCode ?? '';
        const commissionType = item2?.commissionType ?? 'Percentage';
        const commission = item2?.commission ?? 0;

        return {
          operatorsId,
          serviceName,
          operatorName,
          operatorLogo,
          commission,
          commissionType,
          operatorCode,
          surcharge,
          fromAmount,
          toAmount,
          rangesId,
          isRange,
        };
      });
      setTemplate(newTemplate);
      setOriginalTemplate(newTemplate);
      resetResponseData();
    },
    [operators]
  );

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get('/recharge-configurations/operators/?isMargin=Y')
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOperators(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
        setIsLoading(false);
      });
  }, []);

  const readOperatorsMappings = useCallback(() => {
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/recharge-configurations/operators-mapping/?pageNumber=1&recordsPerPage=100000&fOperatorsId=&fApiProvidersId=${id}`
    )
      .then((response) => {
        const { data } = response.data;
        generateTemplate(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
        setIsLoading(false);
      });
  }, [id, generateTemplate]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      template: JSON.stringify(
        template.map((item) => {
          const newItem = { ...item };
          delete newItem.operatorLogo;

          return newItem;
        })
      ),
      isBulkRequest: true,
    };
    API.put(`/recharge-configurations/operators-mapping/bulk/${id}`, formData)
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setResponseData(data ? data : []);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalTemplate(template);
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/recharge-configurations/api-providers';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeOperatorCode = (
    operatorsId,
    operatorCode,
    isRange = 'N',
    rangesId
  ) => {
    const newTemplate = [...template];
    if (isRange === 'Y') {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, operatorCode });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => item.operatorsId === operatorsId
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, operatorCode });
      }
    }
    setTemplate(newTemplate);
  };

  const onChangeCommission = (
    operatorsId,
    commission,
    isRange = 'N',
    rangesId
  ) => {
    const newTemplate = [...template];
    if (isRange === 'Y') {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => item.operatorsId === operatorsId
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    }
    setTemplate(newTemplate);
  };

  const onChangeCommissionType = (
    operatorsId,
    commissionType,
    isRange = 'N',
    rangesId
  ) => {
    const newTemplate = [...template];
    if (isRange === 'Y') {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => item.operatorsId === operatorsId
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    }
    setTemplate(newTemplate);
  };

  useEffect(() => {
    readOperators();
  }, [readOperators]);

  useEffect(() => {
    readOperatorsMappings();
  }, [operators, readOperatorsMappings]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Fieldset>
            {template &&
              template.map(
                (
                  {
                    operatorsId,
                    operatorName,
                    operatorLogo,
                    serviceName,
                    operatorCode,
                    commission,
                    commissionType,
                    surcharge,
                    isRange = 'N',
                    fromAmount,
                    toAmount,
                    rangesId,
                  },
                  index
                ) => {
                  return (
                    <StyledModuleContainer key={index}>
                      <StyledModuleNameContainer>
                        <StyledIconTitleContainer>
                          <StyledIconContainer>
                            <Image
                              source={
                                operatorLogo ? operatorLogo : '/no-image.png'
                              }
                              alt={operatorName}
                              style={{ width: '50px', height: '50px' }}
                            />
                          </StyledIconContainer>
                          <StyledTitleContainer>
                            {operatorName}
                            <StyledSpan>{serviceName}</StyledSpan>
                          </StyledTitleContainer>
                        </StyledIconTitleContainer>
                      </StyledModuleNameContainer>
                      <StyledModuleNameContainer>
                        <Label>Amount Range</Label>
                        <StyledSpan>
                          {formatCurrency(fromAmount)} -{' '}
                          {formatCurrency(toAmount)}
                        </StyledSpan>
                      </StyledModuleNameContainer>
                      <StyledModuleCommissionContainer>
                        <Fieldset style={{ margin: '0px 10px 0px 0px' }}>
                          <Label>Operator Code</Label>
                          <TextInput
                            value={operatorCode}
                            onChange={(operatorCode) =>
                              onChangeOperatorCode(
                                operatorsId,
                                operatorCode,
                                isRange,
                                rangesId
                              )
                            }
                            placeholder="Please enter operator code"
                            maxLength={100}
                            disabled={isLoading === true}
                          />
                        </Fieldset>
                        <Fieldset style={{ margin: '0px 10px 0px 0px' }}>
                          <Label>Commission</Label>
                          <NumberInput
                            value={commission}
                            onChange={(commission) =>
                              onChangeCommission(
                                operatorsId,
                                commission,
                                isRange,
                                rangesId
                              )
                            }
                            placeholder="Please enter commission"
                            maxLength={5}
                            disabled={isLoading === true}
                            pattern={`[0-9.]*`}
                          />
                        </Fieldset>
                        <Fieldset style={{ margin: '0px ' }}>
                          <Label>Commission Type</Label>
                          <Dropdown
                            placeholder="Please select commission type"
                            value={commissionType}
                            onChange={(commissionType) =>
                              onChangeCommissionType(
                                operatorsId,
                                commissionType,
                                isRange,
                                rangesId
                              )
                            }
                            options={[
                              {
                                title: '-- SELECT COMMISSION TYPE --',
                                value: '',
                              },
                              {
                                title: 'Percentage',
                                value: 'Percentage',
                              },
                              {
                                title: 'Flat',
                                value: 'Flat',
                              },
                            ]}
                            disabled={isLoading}
                          />
                        </Fieldset>
                      </StyledModuleCommissionContainer>
                    </StyledModuleContainer>
                  );
                }
              )}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          {responseData?.length > 0 &&
            responseData.map(({ operatorName, errorMessage }) => (
              <Fieldset>
                <MessageBox
                  status={'failed'}
                  message={`${operatorName} : ${errorMessage}`}
                />
              </Fieldset>
            ))}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type="submit">
              Update
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(APIProvidersOperatorsMapping);
