import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
  Dropdown,
} from "../../../components/FormElements";
import { Image } from "../../../components/Image";
import {
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from "../../../components/Styled";
import API from "../../../api";
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from "../../../constants";
import { authData, camelCaseToString, formatCurrency, getReturnUrl } from "../../../utils";

const StyledModuleContainer = styled.section`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  margin: 0px;
  padding: 0px;
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  @media (max-width: 1200px) {
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    label {
      margin: 10px 0px;
    }
  }
`;

const StyledModuleNameContainer = styled.section`
  width: auto;
  min-width: 25%;
  padding: 0px 10px;
  @media (max-width: 1200px) {
    padding: 0px;
    width: 100%;
  }
`;

const StyledModuleCommissionContainer = styled.section`
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #e5e5e5;
  @media (max-width: 1200px) {
    padding: 10px 0px;
    width: 100%;
    border: 0px;
    display: block;
  }
`;

const MarginTemplatesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [template, setTemplate] = useState([]);
  const [originalTemplate, setOriginalTemplate] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setAccountsId] = useState("");
  const { accountType } = authData.get();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setTemplateName("");
    setTemplate(originalTemplate);
  };

  const readAccounts = useCallback(() => {
    API.get("/accounts-management/accounts/?files=No")
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (data?.length > 0) {
            const filteredAccounts = data.filter(
              ({ roleName }) =>
                [
                  ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
                  ACCOUNT_TYPES.DISTRIBUTORS,
                ].includes(roleName) === true
            );
            setAccounts(filteredAccounts);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const readOperators = useCallback(() => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get("/recharge-configurations/operators/?isMargin=Y")
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const newTemplate =
            data?.length > 0 &&
            data.map(
              ({
                id,
                operatorName,
                serviceName,
                operatorLogo,
                surcharge,
                fromAmount,
                toAmount,
                rangesId,
                isRange,
              }) => ({
                operatorsId: id,
                operatorName,
                commission: "",
                commissionType: "Percentage",
                serviceName,
                operatorLogo,
                surcharge,
                fromAmount,
                toAmount,
                isRange,
                rangesId,
              })
            );
          setTemplate(newTemplate);
          setOriginalTemplate(newTemplate);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      forAccountsId: accountsId,
      templateName,
      template: JSON.stringify(
        template.map((item) => {
          const newItem = { ...item };
          delete newItem.operatorLogo;

          return newItem;
        })
      ),
    };
    API.post("/margins/margin-templates", formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readOperators();
    if ([ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(accountType)) {
      readAccounts();
    }
  }, [readOperators, readAccounts]);

  const onChange = (operatorsId, rangesId, commission, isRange = "N") => {
    const newTemplate = [...template];
    if (isRange === "Y") {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => item.operatorsId === operatorsId
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commission });
      }
    }

    setTemplate(newTemplate);
  };

  const onChangeCommissionType = (
    operatorsId,
    rangesId,
    commissionType,
    isRange = "N"
  ) => {
    const newTemplate = [...template];
    if (isRange === "Y") {
      const index = newTemplate.findIndex((item) => item.rangesId === rangesId);
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    } else {
      const index = newTemplate.findIndex(
        (item) => item.operatorsId === operatorsId
      );
      if (index !== -1) {
        const currentValue = newTemplate[index];
        newTemplate.splice(index, 1, { ...currentValue, commissionType });
      }
    }
    setTemplate(newTemplate);
  };

  const accountsOptions = [{ title: "-- SELECT ACCOUNT --", value: 0 }];
  accounts
    .filter((account) => account?.isDeleted === "No")
    .forEach(({ id, isEntity, firstName, lastName, entityName, roleName }) =>
      accountsOptions.push({
        title:
          isEntity === "Yes"
            ? `${entityName} (${camelCaseToString(roleName)})`
            : `${firstName} ${lastName} (${camelCaseToString(roleName)})`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          {[ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(
            accountType
          ) && (
            <Fieldset>
              <Label>Account</Label>
              <Dropdown
                style={{ border: "1px solid #cacaca" }}
                placeholder="Please select account"
                value={accountsId}
                onChange={setAccountsId}
                options={accountsOptions}
                disabled={isLoading}
              />
            </Fieldset>
          )}
          <Fieldset>
            <Label required>Template Name</Label>
            <TextInput
              value={templateName}
              onChange={setTemplateName}
              placeholder="Please enter template name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            {template &&
              template.map(
                (
                  {
                    operatorsId,
                    serviceName,
                    operatorLogo,
                    operatorName,
                    commission,
                    commissionType,
                    surcharge,
                    isRange = "N",
                    fromAmount,
                    toAmount,
                    rangesId,
                  },
                  index
                ) => {
                  return (
                    surcharge === 0 && (
                      <StyledModuleContainer key={index}>
                        <StyledModuleNameContainer>
                          <StyledIconTitleContainer>
                            <StyledIconContainer>
                              <Image
                                source={
                                  operatorLogo ? operatorLogo : "/no-image.png"
                                }
                                alt={operatorName}
                                style={{ width: "50px", height: "50px" }}
                              />
                            </StyledIconContainer>
                            <StyledTitleContainer>
                              {operatorName}
                              <StyledSpan>{serviceName}</StyledSpan>
                            </StyledTitleContainer>
                          </StyledIconTitleContainer>
                        </StyledModuleNameContainer>
                        <StyledModuleNameContainer>
                          <Label>Amount Range</Label>
                          <StyledSpan>
                            {formatCurrency(fromAmount)} -{" "}
                            {formatCurrency(toAmount)}
                          </StyledSpan>
                        </StyledModuleNameContainer>
                        <StyledModuleCommissionContainer>
                          <Fieldset style={{ margin: "0px 10px 0px 0px" }}>
                            <Label required>Commission</Label>
                            <NumberInput
                              value={commission}
                              onChange={(commission) =>
                                onChange(
                                  operatorsId,
                                  rangesId,
                                  commission,
                                  isRange
                                )
                              }
                              placeholder="Please enter commission"
                              maxLength={5}
                              disabled={isLoading === true}
                              pattern={`[0-9.]*`}
                            />
                          </Fieldset>
                          <Fieldset style={{ margin: "0px " }}>
                            <Label required>Commission Type</Label>
                            <Dropdown
                              placeholder="Please select commission type"
                              value={commissionType}
                              onChange={(commissionType) =>
                                onChangeCommissionType(
                                  operatorsId,
                                  rangesId,
                                  commissionType,
                                  isRange
                                )
                              }
                              options={[
                                {
                                  title: "-- SELECT COMMISSION TYPE --",
                                  value: "",
                                },
                                {
                                  title: "Percentage",
                                  value: "Percentage",
                                },
                                {
                                  title: "Flat",
                                  value: "Flat",
                                },
                              ]}
                              disabled={isLoading}
                            />
                          </Fieldset>
                        </StyledModuleCommissionContainer>
                      </StyledModuleContainer>
                    )
                  );
                }
              )}
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading === true} type="submit">
              Create
            </CreateButton>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(MarginTemplatesCreate);
