import React, { useState, useEffect, useCallback } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import { TableBuilder } from "../../../components/TableElements";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  CreateButton,
  MessageBox,
  Dropdown,
  CustomButton,
  Button,
  ResetButton,
} from "../../../components/FormElements";
import Modal from "../../../components/Modal";
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {
  StyledFiltersContainer,
  StyledModalContainer,
} from "../../../components/Styled";
import API from "../../../api";
import {
  ACCOUNT_TYPES,
  API_RESPONSE_TYPES,
  PERMISSION_TYPES,
} from "../../../constants";
import { authData, camelCaseToString } from "../../../utils";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import isEmpty from "lodash/isEmpty";

const RolesDefaultMarginTemplatesTable = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [marginTemplates, setMarginTemplates] = useState([]);
  const [rolesDefaultMarginTemplates, setRolesDefaultMarginTemplates] =
    useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState("");
  const [marginTemplatesId, setMarginTemplatesId] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountsId, setaccountsId] = useState("");
  const { accountType } = authData.get();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const readMarginTemplates = () => {
    API.get(
      `/margins/margin-templates/?readAll=${
        [ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(accountType)
          ? "Yes"
          : "No"
      }`
    ).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setMarginTemplates(data);
      }
    });
  };

  const readData = (accountsId = "") => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.get(`/margins/roles-default-margin-templates?fAccountsId=${accountsId}`)
      .then((response) => {
        const { data } = response.data;
        setRolesDefaultMarginTemplates(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    const formData = {
      accountsId: accountsId,
      templatesId: marginTemplatesId,
    };
    API.put(
      `/margins/roles-default-margin-templates/${modalId}?fAccountsId=${accountsId}`,
      formData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setRolesDefaultMarginTemplates(data);
          setTimeout(() => {
            resetResponseData();
            setShowModal(false);
            setModalId("");
            setMarginTemplatesId("");
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(accountsId);
  };

  const readAccounts = useCallback(() => {
    API.get("/accounts-management/accounts/?files=No")
      .then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (data?.length > 0) {
            const filteredAccounts = data.filter(
              ({ roleName }) =>
                [
                  ACCOUNT_TYPES.MASTER_DISTRIBUTORS,
                  ACCOUNT_TYPES.DISTRIBUTORS,
                ].includes(roleName) === true
            );
            setAccounts(filteredAccounts);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (![ACCOUNT_TYPES.STAFF].includes(accountType)) {
      readData();
    }
    readMarginTemplates();
    readAccounts();
  }, []);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;

  const marginTemplatesOptions = [
    { title: "-- SELECT MARGIN TEMPLATE --", value: "" },
  ];
  marginTemplates
    .filter(({ accountsId: tAccountsId, roleName }) =>
      [ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(accountType)
        ? accountsId === ""
          ? roleName === "admins"
          : tAccountsId === accountsId
        : true
    )
    .forEach(({ id, templateName }) =>
      marginTemplatesOptions.push({
        title: templateName,
        value: id,
      })
    );

  const filterData = (e) => {
    e.preventDefault();
    readData(accountsId);
  };

  const resetFilters = () => {
    setaccountsId("");
    readData(accountsId);
  };

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  const accountsOptions = [{ title: "-- SELECT ACCOUNT --", value: 0 }];
  accounts
    .filter((account) => account?.isDeleted === "No")
    .forEach(({ id, isEntity, firstName, lastName, entityName, roleName }) =>
      accountsOptions.push({
        title:
          isEntity === "Yes"
            ? `${entityName} (${camelCaseToString(roleName)})`
            : `${firstName} ${lastName} (${camelCaseToString(roleName)})`,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      {[ACCOUNT_TYPES.ADMINS, ACCOUNT_TYPES.STAFF].includes(accountType) && (
        <>
          <FiltersButton onClick={onClickShowFilters} value={showFilters} />
          <StyledFiltersContainer className={showFilters ? "show" : ""}>
            <Form method="POST" action="#" onSubmit={filterData}>
              <Fieldset>
                <Label>Account</Label>
                <Dropdown
                  style={{ border: "1px solid #cacaca" }}
                  placeholder="Account"
                  value={accountsId}
                  onChange={setaccountsId}
                  options={accountsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
              <Fieldset>
                <Button disabled={isLoading} type="submit">
                  Filter
                </Button>
                <ResetButton
                  disabled={isLoading}
                  type="button"
                  onClick={resetFilters}
                >
                  Clear
                </ResetButton>
              </Fieldset>
            </Form>
          </StyledFiltersContainer>
        </>
      )}
      <LoadingSpinner
        isLoading={
          !showModal &&
          responseStatus === "" &&
          rolesDefaultMarginTemplates?.length === 0 &&
          isLoading
        }
      />
      <NoData
        status={
          !showModal &&
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          rolesDefaultMarginTemplates?.length === 0
        }
        message={`No roles default margin templates found`}
      />
      {!showModal && responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {rolesDefaultMarginTemplates?.length > 0 && (
        <>
          <TableBuilder
            isLoading={
              !showModal &&
              rolesDefaultMarginTemplates?.length !== 0 &&
              isLoading
            }
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value, item) => (
                  <>
                    {canUpdate && (
                      <CustomButton
                        icon={faEdit}
                        onClick={() => {
                          setModalId(value);
                          setShowModal(true);
                          setMarginTemplatesId(
                            item.templatesId ? item.templatesId : ""
                          );
                        }}
                        style={{ color: "#0095ff" }}
                      />
                    )}
                  </>
                ),
              },
              {
                title: "Role Name",
                dataSelector: "roleName",
                dataType: "string",
                CellRenderer: (value) => camelCaseToString(value),
              },
              {
                title: "Template Name",
                dataSelector: "templateName",
                dataType: "string",
                CellRenderer: (value) => (value ? value : "N/A"),
              },
            ]}
            tableData={rolesDefaultMarginTemplates}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              resetResponseData();
              setShowModal(false);
              setModalId("");
              setMarginTemplatesId("");
            }}
            hideCloseButton
          >
            <StyledModalContainer width={"500px"}>
              <h3>Update Roles Default Margin Template</h3>
              <FormContainer>
                <Form method="POST" action="#" onSubmit={onSubmit}>
                  <Fieldset>
                    <Label>Margin Template</Label>
                    <Dropdown
                      placeholder="Please select margin template"
                      value={marginTemplatesId}
                      onChange={setMarginTemplatesId}
                      options={marginTemplatesOptions}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  {showModal && responseStatus !== "" && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading}
                      type="submit"
                      style={{ width: "auto" }}
                    >
                      Submit
                    </CreateButton>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(RolesDefaultMarginTemplatesTable);
