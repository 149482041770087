import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { faBank } from "@fortawesome/free-solid-svg-icons/faBank";

const StyledWalletLinkSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border-left: 1px solid #e5e5e5;
  position: relative;
  @media (max-width: 600px) {
    position: static;
  }
`;

const StyledWalletLinkIconContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    width: 100%;
    height: 100%;
    border-width: 0px;
    background-color: transparent;
    outline: 0px;
    cursor: pointer;
    transition: background-color 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #e5e5e5;
    }
    &.active {
      background-color: ${({
        theme: {
          colors: { secondaryColor },
        },
      }) => (secondaryColor ? secondaryColor : `#0078ce`)};
      svg {
        color: #fff;
      }
    }
    svg {
      margin: 0px;
      padding: 0px;
      font-size: 20px;
      color: #707070;
    }
  }
`;

const PaymentGatewayDepositCreateLink = () => {
  return (
    <StyledWalletLinkSectionContainer>
      <StyledWalletLinkIconContainer>
        <Link
          to={"/accounting/payment-gateway-deposits/create"}
          title={"Create Payment Gateway Deposit"}
        >
          <FontAwesomeIcon icon={faBank} />
        </Link>
      </StyledWalletLinkIconContainer>
    </StyledWalletLinkSectionContainer>
  );
};

export default PaymentGatewayDepositCreateLink;
