import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  MessageBox,
  Button,
  ResetButton,
  OTPInput,
  NumberInput,
  Textarea,
  Dropdown,
} from '../../../components/FormElements';
import { StyledPara, StyledSpanAsButton } from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES } from '../../../constants';
import { authData } from '../../../utils';

const AppMetaData = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [appName, setAppName] = useState('');
  const [appLogo, setAppLogo] = useState('');
  const [appFavicon, setAppFavicon] = useState('');
  const [homeBanner, setHomeBanner] = useState('');
  const [appPhoneNumber, setAppPhoneNumber] = useState('');
  const [appEmailAddress, setAppEmailAddress] = useState('');
  const [firmName, setFirmName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [firmAddress, setFirmAddress] = useState('');
  const [invoicePrefix, setInvoicePrefix] = useState('');
  const [hsnCode, setHsnCode] = useState('');
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [appBackgroundColor, setAppBackgroundColor] = useState('');
  const [appPrimaryColor, setAppPrimaryColor] = useState('');
  const [appSecondaryColor, setAppSecondaryColor] = useState('');
  const [appTertiaryColor, setAppTertiaryColor] = useState('');
  const [appTableHeaderColor, setAppTableHeaderColor] = useState('');
  const [masterDistributorsCommission, setMasterDistributorsCommission] =
    useState('');
  const [distributorsCommission, setDistributorsCommission] = useState('');
  const [retailersCommission, setRetailersCommission] = useState('');
  const [apiUsersCommission, setApiUsersCommission] = useState('');
  const [upiMinimumAmount, setUpiMinimumAmount] = useState(0);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [routeStage, setRouteStage] = useState('Request');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [appAuthLogoBackgroundColor, setAppAuthLogoBackgroundColor] =
    useState('');
  const [appAuthPrimaryBackgroundColor, setAppAuthPrimaryBackgroundColor] =
    useState('');
  const [appAuthTextColor, setAppAuthTextColor] = useState('');
  const [appAuthPrimaryButtonColor, setAppAuthPrimaryButtonColor] =
    useState('');
  const [appAuthPrimaryButtonTextColor, setAppAuthPrimaryButtonTextColor] =
    useState('');
  const [appAuthSecondaryButtonColor, setAppAuthSecondaryButtonColor] =
    useState('');
  const [appAuthSecondaryButtonTextColor, setAppAuthSecondaryButtonTextColor] =
    useState('');
  const [appAuthOTPBackgroundColor, setAppAuthOTPBackgroundColor] =
    useState('');
  const [appMprimaryColor, setAppMprimaryColor] = useState('');
  const [appMsecondaryColor, setAppMsecondaryColor] = useState('');
  const [appMtertiaryColor, setAppMtertiaryColor] = useState('');
  const [appTextColor, setAppTextColor] = useState('');
  const [appIconColor, setAppIconColor] = useState('');
  const [appIconBackgroundColor, setAppIconBackgroundColor] = useState('');
  const [appButtonBackgroundColor, setAppButtonBackgroundColor] = useState('');
  const [appButtonTextColor, setAppButtonTextColor] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      appName,
      appLogo,
      appFavicon,
      homeBanner,
      appPhoneNumber,
      appEmailAddress,
      appBackgroundColor,
      appPrimaryColor,
      appSecondaryColor,
      appTertiaryColor,
      appTableHeaderColor,
      firmName,
      gstNumber,
      firmAddress,
      invoicePrefix,
      hsnCode,
      invoiceDescription,
      masterDistributorsCommission,
      distributorsCommission,
      retailersCommission,
      apiUsersCommission,
      upiMinimumAmount,
      privacyPolicy,
      termsAndConditions,
      aboutUs,
      appAuthLogoBackgroundColor,
      appAuthPrimaryBackgroundColor,
      appAuthTextColor,
      appAuthPrimaryButtonColor,
      appAuthPrimaryButtonTextColor,
      appAuthSecondaryButtonColor,
      appAuthSecondaryButtonTextColor,
      appAuthOTPBackgroundColor,
      appMprimaryColor,
      appMsecondaryColor,
      appMtertiaryColor,
      appTextColor,
      appIconColor,
      appIconBackgroundColor,
      appButtonBackgroundColor,
      appButtonTextColor,
    } = originalFormData;
    setAppName(appName);
    setAppLogo(appLogo);
    setAppFavicon(appFavicon);
    setHomeBanner(homeBanner);
    setAppPhoneNumber(appPhoneNumber);
    setAppEmailAddress(appEmailAddress);
    setAppBackgroundColor(appBackgroundColor);
    setAppPrimaryColor(appPrimaryColor);
    setAppSecondaryColor(appSecondaryColor);
    setAppTertiaryColor(appTertiaryColor);
    setAppTableHeaderColor(appTableHeaderColor);
    setFirmName(firmName);
    setGstNumber(gstNumber);
    setFirmAddress(firmAddress);
    setInvoicePrefix(invoicePrefix);
    setHsnCode(hsnCode);
    setInvoiceDescription(invoiceDescription);
    setMasterDistributorsCommission(masterDistributorsCommission);
    setDistributorsCommission(distributorsCommission);
    setRetailersCommission(retailersCommission);
    setApiUsersCommission(apiUsersCommission);
    setUpiMinimumAmount(upiMinimumAmount);
    setPrivacyPolicy(privacyPolicy);
    setTermsAndConditions(termsAndConditions);
    setAboutUs(aboutUs);
    setAppAuthLogoBackgroundColor(appAuthLogoBackgroundColor);
    setAppAuthPrimaryBackgroundColor(appAuthPrimaryBackgroundColor);
    setAppAuthTextColor(appAuthTextColor);
    setAppAuthPrimaryButtonColor(appAuthPrimaryButtonColor);
    setAppAuthPrimaryButtonTextColor(appAuthPrimaryButtonTextColor);
    setAppAuthSecondaryButtonColor(appAuthSecondaryButtonColor);
    setAppAuthSecondaryButtonTextColor(appAuthSecondaryButtonTextColor);
    setAppAuthOTPBackgroundColor(appAuthOTPBackgroundColor);
    setAppMprimaryColor(appMprimaryColor);
    setAppMsecondaryColor(appMsecondaryColor);
    setAppMtertiaryColor(appMtertiaryColor);
    setAppTextColor(appTextColor);
    setAppIconColor(appIconColor);
    setAppIconBackgroundColor(appIconBackgroundColor);
    setAppButtonBackgroundColor(appButtonBackgroundColor);
    setAppButtonTextColor(appButtonTextColor);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/configurations/meta-details`)
      .then(({ data }) => {
        const {
          appName,
          appLogo,
          appFavicon,
          homeBanner,
          appPhoneNumber,
          appEmailAddress,
          appBackgroundColor,
          appPrimaryColor,
          appSecondaryColor,
          appTertiaryColor,
          appTableHeaderColor,
          firmName,
          gstNumber,
          firmAddress,
          invoicePrefix,
          hsnCode,
          invoiceDescription,
          masterDistributorsCommission,
          distributorsCommission,
          retailersCommission,
          apiUsersCommission,
          upiMinimumAmount,
          privacyPolicy,
          termsAndConditions,
          aboutUs,
          appAuthLogoBackgroundColor,
          appAuthPrimaryBackgroundColor,
          appAuthTextColor,
          appAuthPrimaryButtonColor,
          appAuthPrimaryButtonTextColor,
          appAuthSecondaryButtonColor,
          appAuthSecondaryButtonTextColor,
          appAuthOTPBackgroundColor,
          appMprimaryColor,
          appMsecondaryColor,
          appMtertiaryColor,
          appTextColor,
          appIconColor,
          appIconBackgroundColor,
          appButtonBackgroundColor,
          appButtonTextColor,
        } = data;
        setAppName(appName);
        setAppLogo(appLogo);
        setAppFavicon(appFavicon);
        setHomeBanner(homeBanner);
        setAppPhoneNumber(appPhoneNumber);
        setAppEmailAddress(appEmailAddress);
        setAppBackgroundColor(appBackgroundColor);
        setAppPrimaryColor(appPrimaryColor);
        setAppSecondaryColor(appSecondaryColor);
        setAppTertiaryColor(appTertiaryColor);
        setAppTableHeaderColor(appTableHeaderColor);
        setFirmName(firmName);
        setGstNumber(gstNumber);
        setFirmAddress(firmAddress);
        setInvoicePrefix(invoicePrefix);
        setHsnCode(hsnCode);
        setInvoiceDescription(invoiceDescription);
        setMasterDistributorsCommission(masterDistributorsCommission);
        setDistributorsCommission(distributorsCommission);
        setRetailersCommission(retailersCommission);
        setApiUsersCommission(apiUsersCommission);
        setUpiMinimumAmount(upiMinimumAmount);
        setPrivacyPolicy(privacyPolicy);
        setTermsAndConditions(termsAndConditions);
        setAboutUs(aboutUs);
        setAppAuthLogoBackgroundColor(appAuthLogoBackgroundColor);
        setAppAuthPrimaryBackgroundColor(appAuthPrimaryBackgroundColor);
        setAppAuthTextColor(appAuthTextColor);
        setAppAuthPrimaryButtonColor(appAuthPrimaryButtonColor);
        setAppAuthPrimaryButtonTextColor(appAuthPrimaryButtonTextColor);
        setAppAuthSecondaryButtonColor(appAuthSecondaryButtonColor);
        setAppAuthSecondaryButtonTextColor(appAuthSecondaryButtonTextColor);
        setAppAuthOTPBackgroundColor(appAuthOTPBackgroundColor);
        setAppMprimaryColor(appMprimaryColor);
        setAppMsecondaryColor(appMsecondaryColor);
        setAppMtertiaryColor(appMtertiaryColor);
        setAppTextColor(appTextColor);
        setAppIconColor(appIconColor);
        setAppIconBackgroundColor(appIconBackgroundColor);
        setAppButtonBackgroundColor(appButtonBackgroundColor);
        setAppButtonTextColor(appButtonTextColor);
        setOriginalFormData({
          appName,
          appLogo,
          appFavicon,
          homeBanner,
          appPhoneNumber,
          appEmailAddress,
          appBackgroundColor,
          appPrimaryColor,
          appSecondaryColor,
          appTertiaryColor,
          appTableHeaderColor,
          firmName,
          gstNumber,
          firmAddress,
          invoicePrefix,
          hsnCode,
          invoiceDescription,
          masterDistributorsCommission,
          distributorsCommission,
          retailersCommission,
          apiUsersCommission,
          upiMinimumAmount,
          privacyPolicy,
          termsAndConditions,
          aboutUs,
          appAuthLogoBackgroundColor,
          appAuthPrimaryBackgroundColor,
          appAuthTextColor,
          appAuthPrimaryButtonColor,
          appAuthPrimaryButtonTextColor,
          appAuthSecondaryButtonColor,
          appAuthSecondaryButtonTextColor,
          appAuthOTPBackgroundColor,
          appMprimaryColor,
          appMsecondaryColor,
          appMtertiaryColor,
          appTextColor,
          appIconColor,
          appIconBackgroundColor,
          appButtonBackgroundColor,
          appButtonTextColor,
        });
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      appName,
      appLogo,
      appFavicon,
      homeBanner,
      appPhoneNumber,
      appEmailAddress,
      firmName,
      gstNumber,
      firmAddress,
      invoicePrefix,
      hsnCode,
      invoiceDescription,
      appBackgroundColor,
      appPrimaryColor,
      appSecondaryColor,
      appTertiaryColor,
      appTableHeaderColor,
      masterDistributorsCommission,
      distributorsCommission,
      retailersCommission,
      apiUsersCommission,
      upiMinimumAmount,
      routeStage,
      oneTimePassword: oneTimePassword ? oneTimePassword.join('') : '',
      requestToken,
      privacyPolicy,
      termsAndConditions,
      aboutUs,
      appAuthLogoBackgroundColor,
      appAuthPrimaryBackgroundColor,
      appAuthTextColor,
      appAuthPrimaryButtonColor,
      appAuthPrimaryButtonTextColor,
      appAuthSecondaryButtonColor,
      appAuthSecondaryButtonTextColor,
      appAuthOTPBackgroundColor,
      appMprimaryColor,
      appMsecondaryColor,
      appMtertiaryColor,
      appTextColor,
      appIconColor,
      appIconBackgroundColor,
      appButtonBackgroundColor,
      appButtonTextColor,
    };
    API.put(`/configurations/meta-details`, formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          if (routeStage === 'Request') {
            setRouteStage('OTPVerification');
            setRequestToken(token);
            setOriginalFormData(formData);
          } else {
            setRouteStage('Request');
            setRequestToken('');
            setOneTimePassword('');
            setTimeout(() => {
              resetResponseData();
            }, 3000);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const { authId } = authData.get();
    const formData = {
      requestType: 'APP_META_DATA',
      mobileNumber: authId,
      requestToken,
    };
    API.post('/auth/resend-otp', formData)
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method="POST" action="#" onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>App Name</Label>
                <TextInput
                  value={appName}
                  onChange={setAppName}
                  placeholder="Please enter app name"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>App Logo Url</Label>
                <TextInput
                  value={appLogo}
                  onChange={setAppLogo}
                  placeholder="Please enter app logo url"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>App Favicon Url</Label>
                <TextInput
                  value={appFavicon}
                  onChange={setAppFavicon}
                  placeholder="Please enter app favicon url"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Home Banner</Label>
            <TextInput
              value={homeBanner}
              onChange={setHomeBanner}
              placeholder="Please enter home banner url"
              disabled={routeStage === 'OTPVerification' || isLoading === true}
            />
          </Fieldset>{' '}
          <Fieldset>
            <Label>Background Color</Label>
            <TextInput
              type="color"
              value={appBackgroundColor}
              onChange={setAppBackgroundColor}
              placeholder="Please select background color"
              disabled={routeStage === 'OTPVerification' || isLoading === true}
              style={{ padding: '0px', height: '48px', width: '100%' }}
            />
          </Fieldset>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>Primary Color</Label>
                <TextInput
                  type="color"
                  value={appPrimaryColor}
                  onChange={setAppPrimaryColor}
                  placeholder="Please select primary color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Secondary Color</Label>
                <TextInput
                  type="color"
                  value={appSecondaryColor}
                  onChange={setAppSecondaryColor}
                  placeholder="Please select secondary color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Tertiary Color</Label>
                <TextInput
                  type="color"
                  value={appTertiaryColor}
                  onChange={setAppTertiaryColor}
                  placeholder="Please select tertiary color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Table Header Color</Label>
                <TextInput
                  type="color"
                  value={appTableHeaderColor}
                  onChange={setAppTableHeaderColor}
                  placeholder="Please select table header color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {/** START */}
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>APP Auth Logo Background Color</Label>
                <TextInput
                  type="color"
                  value={appAuthLogoBackgroundColor}
                  onChange={setAppAuthLogoBackgroundColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth Primary Background Color</Label>
                <TextInput
                  type="color"
                  value={appAuthPrimaryBackgroundColor}
                  onChange={setAppAuthPrimaryBackgroundColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth Text Color</Label>
                <TextInput
                  type="color"
                  value={appAuthTextColor}
                  onChange={setAppAuthTextColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth OTP Background Color</Label>
                <TextInput
                  type="color"
                  value={appAuthOTPBackgroundColor}
                  onChange={setAppAuthOTPBackgroundColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>APP Auth Primary Button Color</Label>
                <TextInput
                  type="color"
                  value={appAuthPrimaryButtonColor}
                  onChange={setAppAuthPrimaryButtonColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth Primary Button Text Color</Label>
                <TextInput
                  type="color"
                  value={appAuthPrimaryButtonTextColor}
                  onChange={setAppAuthPrimaryButtonTextColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth Secondary Button Color</Label>
                <TextInput
                  type="color"
                  value={appAuthSecondaryButtonColor}
                  onChange={setAppAuthSecondaryButtonColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Auth Secondary Button Text Color</Label>
                <TextInput
                  type="color"
                  value={appAuthSecondaryButtonTextColor}
                  onChange={setAppAuthSecondaryButtonTextColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>APP Primary Color</Label>
                <TextInput
                  type="color"
                  value={appMprimaryColor}
                  onChange={setAppMprimaryColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Secondary Color</Label>
                <TextInput
                  type="color"
                  value={appMsecondaryColor}
                  onChange={setAppMsecondaryColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Tertiary Color</Label>
                <TextInput
                  type="color"
                  value={appMtertiaryColor}
                  onChange={setAppMtertiaryColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Text Color</Label>
                <TextInput
                  type="color"
                  value={appTextColor}
                  onChange={setAppTextColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label>APP Icon Color</Label>
                <TextInput
                  type="color"
                  value={appIconColor}
                  onChange={setAppIconColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Icon Background Color</Label>
                <TextInput
                  type="color"
                  value={appIconBackgroundColor}
                  onChange={setAppIconBackgroundColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Button Color</Label>
                <TextInput
                  type="color"
                  value={appButtonBackgroundColor}
                  onChange={setAppButtonBackgroundColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>APP Button Text Color</Label>
                <TextInput
                  type="color"
                  value={appButtonTextColor}
                  onChange={setAppButtonTextColor}
                  placeholder="Please select color"
                  disabled={
                    routeStage === 'OTPVerification' || isLoading === true
                  }
                  style={{ padding: '0px', height: '48px', width: '100%' }}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {/** END */}
          <Grid
            columns={
              'calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>Master Distributors Commission</Label>
                <NumberInput
                  value={masterDistributorsCommission}
                  onChange={setMasterDistributorsCommission}
                  placeholder="Please enter master distributors commission"
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Distributors Commission</Label>
                <NumberInput
                  value={distributorsCommission}
                  onChange={setDistributorsCommission}
                  placeholder="Please enter distributors commission"
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Retailers Commission</Label>
                <NumberInput
                  value={retailersCommission}
                  onChange={setRetailersCommission}
                  placeholder="Please enter retailers commission"
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>API Users Commission</Label>
                <NumberInput
                  value={apiUsersCommission}
                  onChange={setApiUsersCommission}
                  placeholder="Please enter api users commission"
                  maxLength={100}
                  disabled={isLoading === true}
                  pattern={`[0-9.]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>UPI Minimum Amount</Label>
            <NumberInput
              value={upiMinimumAmount}
              onChange={setUpiMinimumAmount}
              placeholder="Please enter upi minimum amount"
              maxLength={100}
              disabled={isLoading === true}
              pattern={`[0-9.]*`}
            />
          </Fieldset>
          <Fieldset>
            <Label>Privacy Policy ( Text or HTML Body )</Label>
            <Textarea
              placeholder="Please enter privacy policy"
              value={privacyPolicy}
              onChange={(e) => {
                setPrivacyPolicy(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Terms & Conditions ( Text or HTML Body )</Label>
            <Textarea
              placeholder="Please enter terms and conditions"
              value={termsAndConditions}
              onChange={(e) => {
                setTermsAndConditions(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>About Us ( Text or HTML Body )</Label>
            <Textarea
              placeholder="Please enter about us"
              value={aboutUs}
              onChange={(e) => {
                setAboutUs(e.target.value);
              }}
              disabled={isLoading}
            />
          </Fieldset>
          {routeStage === 'OTPVerification' && (
            <Grid>
              <GridItem>
                <StyledPara>
                  Please enter your OTP sent to your mobile number
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs="center"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              {routeStage === 'OTPVerification' ? 'Verify & Update' : 'Update'}
            </Button>
            {routeStage === 'OTPVerification' && (
              <StyledSpanAsButton onClick={onClickResendOTP}>
                Resend OTP
              </StyledSpanAsButton>
            )}
            {routeStage === 'Request' && (
              <ResetButton
                disabled={isLoading === true}
                type="button"
                onClick={resetForm}
              >
                Reset
              </ResetButton>
            )}
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AppMetaData);
