import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import StatusHighliter from '../../../components/StatusHighliter/StatusHighliter';
import {
  CustomConfirmButton,
  Switch,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import Pagination from '../../../components/Pagination/Pagination';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  formatCurrency,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';
import {
  StyledLink,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledFiltersContainer,
} from '../../../components/Styled';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';

const ServicesTable = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [services, setServices] = useState([]);
  const [serviceName, setServiceName] = useState('');

  const resetForm = () => {
    setServiceName('');
    readData();
  };

  const readData = useCallback(
    (serviceName = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setServices([]);
      API.get(
        `/recharge-configurations/services/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServiceName=${serviceName}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo.totalRecords);
            }
            setServices(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(serviceName);
  };

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.serviceStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(
      `/recharge-configurations/services/${newData.id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServiceName=${serviceName}`,
      newData
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setServices(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(
      `/recharge-configurations/services/${id}?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&fServiceName=${serviceName}`
    )
      .then((response) => {
        const { status, message, data } = response.data;
        if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
          redirectToPageOne();
        } else {
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setServices(data);
          }
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(serviceName);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading {...props} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Service Name</Label>
            <TextInput
              value={serviceName}
              onChange={setServiceName}
              placeholder="Service Name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && services?.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          services?.length === 0
        }
        message={`No services found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {services?.length > 0 && (
        <>
          <TableBuilder
            isLoading={services?.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Service',
                dataSelector: 'serviceName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={
                          item.serviceIcon ? item.serviceIcon : '/no-image.png'
                        }
                        alt={item.value}
                        style={{
                          width: '50px',
                          height: '50px',
                        }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>{value}</StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'APP Icon',
                dataSelector: 'serviceAppIcon',
                dataType: 'string',
                align: 'center',
                CellRenderer: (value, item) => (
                  <Image
                    source={value && value !== 'null' ? value : '/no-image.png'}
                    alt={item.value}
                    style={{
                      width: '50px',
                      height: '50px',
                    }}
                  />
                ),
              },
              {
                title: 'Surcharge',
                dataSelector: 'surcharge',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => formatCurrency(value),
              },
              {
                title: 'Master Distributors Commission',
                dataSelector: 'defaultMasterDistributorsShare',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => `${value}%`,
              },
              {
                title: 'Distributors Commission',
                dataSelector: 'defaultDistributorsShare',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => `${value}%`,
              },
              {
                title: 'Retailers Commission',
                dataSelector: 'defaultRetailersShare',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => `${value}%`,
              },
              {
                title: 'API Users Commission',
                dataSelector: 'defaultApiUsersShare',
                dataType: 'number',
                align: 'center',
                CellRenderer: (value) => `${value}%`,
              },
              {
                title: 'Plan API Status',
                dataSelector: 'enablePlanApi',
                dataType: 'staring',
                align: 'center',
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={value === 'Yes' ? 'green' : 'red'}
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: 'Bill Fetch API Status',
                dataSelector: 'enableBillFetchApi',
                dataType: 'staring',
                align: 'center',
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={value === 'Yes' ? 'green' : 'red'}
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: 'Status',
                dataSelector: 'serviceStatus',
                dataType: 'string',
                align: 'center',
                canSort: false,
                CellRenderer: (value, data) => {
                  return (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, data)}
                    />
                  );
                },
              },
            ]}
            tableData={services}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(ServicesTable);
